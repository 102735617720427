import { theme } from './theme';

export class HomeyColors {
  constructor() {
    throw new Error('dont make instances of this class');
  }

  static getColorForUri(uri: string) {
    switch (uri) {
      case 'homey:manager:insights':
        return theme.color.system_insights;
      case 'homey:manager:audio':
        return theme.color.system_audio;
      case 'homey:manager:ledring':
        return theme.color.system_ledring;
      case 'homey:manager:i18n':
        return theme.color.system_language;
      case 'homey:manager:presence':
        return theme.color.system_presence;
      case 'homey:manager:geolocation':
        return theme.color.system_location;
      case 'homey:manager:reminder':
        return theme.color.system_reminders;
      case 'homey:manager:cron':
        return theme.color.system_datetime;
      case 'homey:manager:alarms':
        return theme.color.system_alarms;
      case 'homey:manager:notifications':
        return theme.color.system_timeline_notifications;
      case 'homey:manager:zwave':
        return theme.color.system_zwave;
      case 'homey:manager:zigbee':
        return theme.color.system_zigbee;
      case 'homey:manager:users':
        return theme.color.system_family;
      case 'homey:manager:updates':
        return theme.color.system_updates;
      case 'homey:manager:experiments':
        return theme.color.system_experiments;
      case 'homey:manager:speech-input':
      case 'homey:manager:speech-output':
        return theme.color.system_speech;
      case 'homey:manager:flow':
      case 'homey:manager:flowtoken':
        return theme.color.system_flow;
      case 'homey:manager:logic':
        return theme.color.system_logic;
      case 'homey:manager:mobile':
      case 'homey:manager:nfc':
        return theme.color.system_push_notifications;
      case 'homey:manager:devices':
        return theme.color.system_devices;
      case 'homey:manager:vdevice':
        return theme.color.system_devices;
      case 'homey:manager:apps':
        return theme.color.system_apps;
      case 'homey:manager:zones':
        return theme.color.system_zone;
      case 'homey:manager:security':
        return theme.color.system_security;
      case 'homey:manager:system':
        return theme.color.system_general;
      case 'homey:manager:energy':
        return theme.color.system_energy;
      case 'homey:manager:backup':
        return theme.color.system_backups;
      default:
        return '#dddddd';
    }
  }
}
