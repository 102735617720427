import { Suspense, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import Editor from '@monaco-editor/react';
import styled from '@emotion/styled';
import { css as cn } from '@emotion/css';
import { useOverlayTriggerState } from 'react-stately';

import { AppStore } from '../../../../store/apps/AppStore';
import { getUsedTokensKeys } from '../../view-advanced-flow/useTreeTokenKeys';
import { applyMonacoSettings, fetchTypes } from '../../../script/ScriptEditor';
import { enqueueTask } from '../../../../lib/enqueueTask';

import { useCurrentProps } from '../../../../hooks/useCurrentProps';
import { useApi } from '../../../../store/HomeyStore';
import { useOverlayTrigger } from '../../../../components/overlay/useOverlayTrigger';
import { useTokenContext } from '../../TokenContext';
import { useWebAppSettings } from '../../../../store/web-app-settings/useWebAppSettings';

import { argInput } from '../argInput';

import { theme } from '../../../../theme/theme';

import { AnimationRemain } from '../../../../components/animation/AnimationRemain';
import { Overlay } from '../../../../components/overlay/Overlay';
import { PlayButton } from '../../../../components/buttons/PlayButton';
import { Icon } from '../../../../components/common/Icon';
import { ExternalLink } from '../../../../components/common/link/ExernalLink';

import { FlowTestForm } from '../../FlowTestForm';
import { EditableInput } from '../argument-text/EditableInput';
import { ArgumentHomeyScriptOutput } from './ArgumentHomeyScriptOutput';
import { DeprecatedLabel } from '../../../script/DeprecatedLabel';

import { iconExternalLink } from '../../../../theme/icons/interface/external-link';

function TestForm(props) {
  const { tokensByKey } = useTokenContext();

  const definedTokens = {};

  for (const usedTokenKey of props.usedTokenKeys) {
    definedTokens[usedTokenKey] = tokensByKey[usedTokenKey];
  }

  return <FlowTestForm tokens={definedTokens} onStartRequest={props.onStartRequest} />;
}

export function CodeInput(props) {
  const { settings } = useWebAppSettings();

  const editorInstanceRef = useRef();
  const monacoRef = useRef();

  const { api } = useApi();

  const currentProps = useCurrentProps({
    onSaveRequest: props.onSaveRequest,
  });

  // eslint-disable-next-line no-unused-vars
  const [isEditorReady, setIsEditorReady] = useState(false);
  const [result, setResult] = useState({ logs: [], pending: false });

  function handleEditorChange(value, event) {
    // console.log(value);
    // console.log(event);
    props.onChange(value);
  }

  function handleEditorDidMount(editorInstance, monaco) {
    // EditorInstance = monaco.editor.create()
    // https://microsoft.github.io/monaco-editor/api/interfaces/monaco.editor.IStandaloneCodeEditor.html
    // https://microsoft.github.io/monaco-editor/api/modules/monaco.editor.html

    editorInstanceRef.current = editorInstance;
    monacoRef.current = monaco;

    // console.log(editorInstance);

    applyMonacoSettings(monaco);
    fetchTypes(monaco);

    editorInstance.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS, () => {
      currentProps.onSaveRequest();
    });

    editorInstance.addCommand(
      monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.KeyR,
      () => {}
    );

    editorInstance.updateOptions({
      tabSize: 2,
      wordWrap: 'wordWrapColumn',
      wordWrapColumn: 120,
      codeLens: true,
      padding: {
        bottom: 0,
        top: 0,
      },
      scrollbar: {
        verticalSliderSize: 10,
        verticalScrollbarSize: 10,
      },
      minimap: { enabled: false },
      fontSize: 14,
      fontFamily: 'Roboto Mono, monospace',
      fontWeight: 400,
      // Needed for overflowing popover.
      fixedOverflowWidgets: true,
      // Hides the outline/border around a line.
      renderLineHighlight: false,
      // Hides cursor position in scrollbar.
      hideCursorInOverviewRuler: true,
      // Hides the outline/border around the scrollbar.
      overviewRulerBorder: false,
    });

    // There was a bug when this was called without a delay. The whole editor would be shifted to the
    // right on the second opening. So if you remove this make sure that you open the argument a few
    // times and check if the editor is always in the correct position.
    enqueueTask(() => {
      editorInstance.focus();
    });

    setIsEditorReady(true);

    // I dont see any other way to do this right now. If we don't do this the caret and the content
    // might become out of sync. This can be reproduced by disabled browser cache and opening the
    // code argument. The same problem doesn't seem to arise in de standard script editor.
    setTimeout(() => {
      monaco.editor.remeasureFonts();
    }, 2000);
  }

  const editableInputRef = useRef();

  useEffect(() => {
    function listener({ script, text }) {
      setResult((prevState) => {
        return {
          ...prevState,
          logs: [...prevState.logs, text],
        };
      });
    }

    const app = AppStore.get().data?.['com.athom.homeyscript'];
    app?.addListener('log', listener);

    return function () {
      app?.removeListener('log', listener);
    };
  }, []);

  const overlayTriggerRef = useRef();
  const overlayTriggerState = useOverlayTriggerState({});
  const overlayTrigger = useOverlayTrigger(
    { type: 'menu' },
    overlayTriggerState,
    overlayTriggerRef
  );

  function handleStartRequest({ tokens }) {
    overlayTriggerState.close();
    (async () => {
      if (result.pending) return;

      ReactDOM.flushSync(() => {
        setResult({ logs: [], pending: true });
      });

      try {
        const minWait = new Promise((resolve) => setTimeout(resolve, 500));

        const args = {
          code: editorInstanceRef.current.getValue(),
        };

        if (props.argumentText != null) {
          args.argument = editableInputRef.current?.getValue();
        }

        // Will reject on long-running code.
        const response = await api.flow._call(
          'POST',
          `/flowcardaction/${props.cardData.ownerUri}/${props.cardData.id}/run`,
          {
            body: {
              args,
              state: {
                realtime: true,
              },
              droptoken: props.cardData.droptoken,
              // duration: duration,
              tokens: tokens,
            },
          }
        );
        await minWait;

        if (response.error != null) {
          setResult((prevState) => {
            return {
              ...prevState,
              logs: [...prevState.logs, JSON.stringify(response.error)],
              pending: false,
            };
          });
        } else {
          setResult((prevState) => {
            return {
              ...prevState,
              pending: false,
            };
          });
        }

        // console.log(response);
      } catch (error) {
        setResult((prevState) => {
          return {
            ...prevState,
            logs: [...prevState.logs, JSON.stringify(error)],
            pending: false,
          };
        });
      }
    })().catch(console.error);
  }

  function handleTestPress() {
    const usedTokenKeys = getUsedTokensKeys({
      args: { text: editableInputRef.current?.getValue() ?? '' },
    });

    if (Object.values(usedTokenKeys).length > 0) {
      overlayTriggerState.open();
      return;
    }

    handleStartRequest({ tokens: {} });
  }

  return (
    <argInput.InputContainer style={{ '--width': '760px', '--input-container-padding': '20px' }}>
      <CodeInput.Header>
        <AnimationRemain condition={overlayTriggerState.isOpen} delay={200}>
          {(animationRemainProps) => {
            const usedTokenKeys = getUsedTokensKeys({
              args: { text: editableInputRef.current.getValue() },
            });

            return (
              <Overlay
                targetRef={overlayTriggerRef}
                overlayProps={overlayTrigger.overlayProps}
                overlayTriggerState={overlayTriggerState}
                animationRemainProps={animationRemainProps}
                offset={10}
              >
                <TestForm usedTokenKeys={usedTokenKeys} onStartRequest={handleStartRequest} />
              </Overlay>
            );
          }}
        </AnimationRemain>

        <PlayButton
          {...overlayTrigger.triggerProps}
          ref={overlayTriggerRef}
          isDisabled={result.pending}
          isDisabledStyle="readonly"
          isTestingRoot={result.pending}
          isTransformDisabled={true}
          onPress={handleTestPress}
        >
          Test
        </PlayButton>

        {props.argumentText != null && (
          <Suspense fallback={null}>
            <CodeInput.EditableInputWrapper>
              <EditableInput
                ref={editableInputRef}
                layout="contained"
                autoFocus={false}
                fieldProps={{}}
                placeholder="Argument"
                chunks={props.argumentText.chunks}
                onChange={props.argumentText.onChange}
                onSaveRequest={props.onSaveRequest}
                onCancelRequest={props.onCancelRequest}
              />
            </CodeInput.EditableInputWrapper>
          </Suspense>
        )}

        <CodeInput.HeaderRightContainer>
          <CodeInput.Link url="https://athombv.github.io/com.athom.homeyscript/global.html">
            Docs
            <Icon
              url={iconExternalLink}
              color={theme.color.highlight}
              size={theme.icon.size_tiny}
            />
          </CodeInput.Link>

          {props.isMigrateable && <DeprecatedLabel />}
        </CodeInput.HeaderRightContainer>
      </CodeInput.Header>

      <CodeInput.Content>
        <CodeInput.Editor
          wrapperProps={{
            className: editorWrapperClassName,
          }}
          defaultLanguage="javascript"
          defaultValue={props.value}
          theme={settings.theme.darkMode ? 'vs-dark' : 'light'}
          loading={<div />}
          onMount={handleEditorDidMount}
          onChange={handleEditorChange}
          // onValidate={handleEditorValidation}
        />

        <CodeInput.OutputWrapper>
          <ArgumentHomeyScriptOutput logs={result.logs} />
        </CodeInput.OutputWrapper>
      </CodeInput.Content>
    </argInput.InputContainer>
  );
}

CodeInput.Link = styled(ExternalLink)`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 5px;
  align-items: center;
  text-decoration: none;
  outline: 0;
  padding-right: 5px;

  ${Icon.S.Root} {
    transform: translateY(1px);
  }

  &:hover {
    text-decoration: underline;
  }
`;

CodeInput.HeaderRightContainer = styled.div`
  display: flex;
  gap: ${theme.su(1)};
`;

CodeInput.Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 auto;
  padding-bottom: 10px;

  --editable-border-radius: ${theme.borderRadius.small};
`;

CodeInput.EditableInputWrapper = styled.div`
  flex: 1 1 auto;
  padding-left: 20px;
  padding-right: 10px;
`;

CodeInput.Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  gap: 20px;
  overflow: hidden;
  width: 100%;
`;

const editorWrapperClassName = cn`
  display: flex;
  flex-direction: column;
  // align-items: stretch;
  position: relative;
  flex: 1 1 180px;
  width: 100%;
  border-radius: ${theme.borderRadius.small};
  border: 1px solid ${theme.color.line};
  overflow: hidden;

  .margin {
    background: transparent !important;
  }

  .monaco-editor {
    background: transparent !important;
  }

  .monaco-editor-background {
    background: transparent !important;
  }

  .overflow-guard {

  }

  .slider {
    border-radius: ${theme.borderRadius.small};
    width: 6px !important;
  }
`;

CodeInput.OutputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 160px;
  padding: 0;
  // width: 320px;
  // margin-left: 10px;
  border-radius: ${theme.borderRadius.small};
  border: 1px solid ${theme.color.line};
  overflow: hidden;
`;

CodeInput.Editor = styled(Editor, {
  shouldForwardProp: (prop) => {
    // theme prop is prevented by emotion
    // on custom components
    return true;
  },
})`
  padding: 5px 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
