import React, { useEffect, useState, useMemo, useRef } from 'react';
import styled from '@emotion/styled';

import { d3 } from '../../../lib/d3';
import {
  getThermostatMode,
  getThermostatGradientForMode,
} from '../../../lib/capabilities/thermostat';
import { setBackgroundAnimationColor } from '../ControlsBackground';

import { useI18n } from '../../../hooks/useI18nFormatters';

const RADIUS = 140;
const COLORS = {
  ACTIVE: 'rgb(255,255,255)',
  INACTIVE: 'rgb(255,255,255,0.5)',
};
const HANDLE_WIDTH = 24;

export function ThermostatRadial({
  min,
  max,
  step,
  units,
  targetValue,
  measureValue,
  modeValue,
  onTargetValueChange,
  ...props
}) {
  const { i18n } = useI18n();
  const handleRef = useRef();
  const handleVisualRef = useRef();
  const range = Math.abs(max - min);
  const steps = range / step;
  const rangeMax = range;
  const rangeMin = 0;

  const scalers = useMemo(() => {
    return {
      toComponent(value) {
        if (value == null) return null;

        return ((value - min) / (max - min)) * (rangeMax - rangeMin) + rangeMin;
      },
      toCapability(value) {
        if (value == null) return null;

        return ((value - rangeMin) / (rangeMax - rangeMin)) * (max - min) + min;
      },
    };
  }, [min, max, rangeMax, rangeMin]);

  const [targetStateValue, setTargetStateValue] = useState(scalers.toComponent(targetValue));

  const mode = getThermostatMode({
    mode: modeValue,
    measure: measureValue,
    target: scalers.toCapability(roundToStep(targetStateValue, step)),
  });
  const color = getThermostatGradientForMode(mode);
  const modeText = getModeText({ mode, i18n });

  useEffect(() => {
    setTargetStateValue(scalers.toComponent(targetValue));
  }, [targetValue, scalers]);

  useEffect(() => {
    setHandle({
      step,
      steps,
      targetStateValue,
      handleElement: handleRef.current,
      handleVisualElement: handleVisualRef.current,
    });
  }, [step, steps, targetStateValue, scalers]);

  useEffect(() => {
    setBackgroundAnimationColor({
      start: color.start,
      stop: color.stop,
    });
  }, [color.start, color.stop]);

  useEffect(() => {
    createDrag({
      step,
      steps,
      setTargetValue: (value) => {
        setTargetStateValue(value);
        onTargetValueChange?.(scalers.toCapability(roundToStep(value, step)));
      },
      handleElement: handleRef.current,
      handleVisualElement: handleVisualRef.current,
    });
  }, [step, steps, setTargetStateValue, onTargetValueChange, scalers]);

  let targetValueDecimals = 1;
  let measureValueDecimals = 2;

  // We override for Celcius and otherwise we use whatever is in decimals.
  // If you want to know why I dont know either. Probably should be changed
  // to proper defaults in HomeyLib.
  if (units !== '°C') {
    targetValueDecimals = props.targetValueDecimals ?? 1;
    measureValueDecimals = props.measureValueDecimals ?? 2;
  }

  return (
    <svg viewBox="-200 -200 400 400" xmlns="http://www.w3.org/2000/svg">
      <g>
        <Text
          x={0}
          y={0}
          fill="white"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize="68px"
        >
          {targetStateValue != null
            ? `${scalers
                .toCapability(roundToStep(targetStateValue, step))
                ?.toFixed(targetValueDecimals)}°`
            : '-'}
        </Text>
        <Text
          x={0}
          y={42}
          fill="white"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize="16px"
        >
          {modeText}
        </Text>
      </g>

      {props.hideMeasureTemperature !== true && (
        <>
          <Text
            x={0}
            y={100}
            fill="white"
            dominantBaseline="middle"
            textAnchor="middle"
            fontSize="22px"
          >
            {measureValue != null ? `${measureValue.toFixed(measureValueDecimals)}°` : `-`}
          </Text>

          <Text
            x={0}
            y={120}
            fill={COLORS.INACTIVE}
            dominantBaseline="middle"
            textAnchor="middle"
            fontSize="14px"
          >
            {i18n.messageFormatter('device.thermostat.currentTemp')}
          </Text>
        </>
      )}

      {renderLineElements({
        step,
        steps,
        targetStateValue,
        measureValue: scalers.toComponent(measureValue),
      })}

      <HandleVisual
        ref={handleVisualRef}
        x1={0}
        y1={0}
        x2={0}
        y2={0}
        stroke="white"
        strokeLinecap="round"
        strokeWidth={6}
      />

      <Handle
        ref={handleRef}
        x1={0}
        y1={0}
        x2={0}
        y2={0}
        stroke="red"
        strokeLinecap="round"
        strokeWidth={32}
      />
    </svg>
  );
}

function renderLineElements({ step, steps, targetStateValue, measureValue }) {
  const lineElements = [];
  const availableRadians = Math.PI * 2 - Math.PI / 2;
  const radianStepChunk = availableRadians / steps;
  const difference = targetStateValue - measureValue;

  for (let index = 0; index < steps + 1; index++) {
    const rotatedRadians = ((Math.PI * 2) / 4) * 1.5 + radianStepChunk * index;
    let color = COLORS.INACTIVE;
    let strokeWidth = 2;

    if (targetStateValue / step === index || measureValue / step === index) {
      color = COLORS.ACTIVE;
      strokeWidth = 4;
    }

    if (
      (difference < 0 && targetStateValue / step <= index && measureValue / step >= index) ||
      (difference > 0 && targetStateValue / step >= index && measureValue / step <= index)
    ) {
      color = COLORS.ACTIVE;
    }

    const position = getPosition({
      radians: rotatedRadians,
    });

    lineElements.push(
      <line
        key={index}
        {...position}
        strokeLinecap="round"
        style={{
          stroke: color,
          strokeWidth: strokeWidth,
        }}
      />
    );
  }
  return lineElements;
}

function setHandle({ step, steps, targetStateValue, handleElement, handleVisualElement }) {
  const targetValueStep = (targetStateValue ?? 0) / step;
  const availableRadians = Math.PI * 2 - Math.PI / 2;
  const radianStepChunk = availableRadians / steps;
  const rotatedRadians = ((Math.PI * 2) / 4) * 1.5 + radianStepChunk * targetValueStep;

  setHandlePosition({
    handle: d3.select(handleElement),
    handleVisual: d3.select(handleVisualElement),
    negate: false,
    position: getPosition({
      radians: rotatedRadians,
      width: HANDLE_WIDTH,
    }),
  });
}

function setHandlePosition({ handle, handleVisual, negate = true, position }) {
  handle
    .raise()
    .attr('x1', position.x1)
    .attr('y1', negate ? -position.y1 : position.y1)
    .attr('x2', position.x2)
    .attr('y2', negate ? -position.y2 : position.y2);

  handleVisual
    .raise()
    .attr('x1', position.x1)
    .attr('y1', negate ? -position.y1 : position.y1)
    .attr('x2', position.x2)
    .attr('y2', negate ? -position.y2 : position.y2);
}

function getPosition({ radians, angle, width = 16 }) {
  const outerRadius = RADIUS + width / 2;
  const innerRadius = RADIUS - width / 2;

  radians = angle != null ? angle * (Math.PI / 180) : radians;

  const x1 = outerRadius * Math.cos(radians);
  const y1 = outerRadius * Math.sin(radians);
  const x2 = innerRadius * Math.cos(radians);
  const y2 = innerRadius * Math.sin(radians);
  return { x1, y1, x2, y2 };
}

function createDrag({ step, steps, setTargetValue, handleElement, handleVisualElement }) {
  const handle = d3.select(handleElement);
  const handleVisual = d3.select(handleVisualElement);
  handle.on('.drag', null);
  handle.call(d3.drag().on('start', handleDragStart));

  function handleDragStart(event) {
    event.on('drag', handleDrag).on('end', handleDragEnd);
  }

  function handleDrag(event) {
    const x = event.x;
    const flippedY = event.y;
    const y = -flippedY;

    const radians = Math.atan2(y, x);
    const angle = (radians * 180) / Math.PI;

    let position = null;

    switch (true) {
      case angle >= -135 && angle <= -90:
        position = getPosition({
          angle: -135,
          width: HANDLE_WIDTH,
        });
        break;
      case angle <= -45 && angle > -90:
        position = getPosition({
          angle: -45,
          width: HANDLE_WIDTH,
        });
        break;
      default:
        position = getPosition({ radians, width: HANDLE_WIDTH });
        break;
    }

    setHandlePosition({
      handle,
      handleVisual,
      position,
    });

    setValueForXY({
      x: position.x1,
      y: position.y1,
      step,
      steps,
      setTargetValue,
    });
  }

  function handleDragEnd() {
    const x = handle.attr('x1');
    const flippedY = handle.attr('y1');
    const y = -flippedY;
    setValueForXY({ x, y, step, steps, setTargetValue });
  }
}

function setValueForXY({ x, y, step, steps, setTargetValue }) {
  const radians = (Math.atan2(y, x) + Math.PI * 2) % (Math.PI * 2);
  const rotatedRadians = (radians + Math.PI * 0.25) % (2 * Math.PI);
  const angle = (rotatedRadians * 180) / Math.PI;
  const angleChunks = 270 / steps;
  const targetValue = (steps - angle / angleChunks) * step;
  setTargetValue(targetValue);
}

function roundToStep(value, step) {
  if (value == null) return null;
  return Math.round(value / step) * step;
}

function getModeText({ mode, i18n }) {
  switch (mode) {
    case 'heat':
      return i18n.messageFormatter('device.thermostat.heatingTowards');
    case 'cool':
      return i18n.messageFormatter('device.thermostat.coolingDown');
    default:
      return '';
  }
}

const Handle = styled.line`
  cursor: pointer;
  opacity: 0;
`;

const HandleVisual = styled.line`
  pointer-events: none;
`;

const Text = styled.text`
  font-size: ${(props) => props.fontSize};
`;
