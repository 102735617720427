import { useState, useLayoutEffect } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

import { setBackgroundAnimationUrl } from '../ControlsBackground';

import { useImage } from '../../../store/images/useImages';
import { useBaseUrl } from '../../../store/HomeyStore';

import default_album_art from '../images/media/default_album_art.png';

export function MediaArt({ device }) {
  const [isLoaded, setIsLoaded] = useState(false);

  const deviceImage = device.images.find((image) => {
    return image.type === 'media' && image.id === 'albumart';
  });

  const { baseUrl } = useBaseUrl();
  const { image } = useImage({ imageKey: deviceImage?.imageObj.id });
  const url = image != null ? `${baseUrl}${image.url}?${image.lastUpdated}` : null;

  useLayoutEffect(() => {
    if (url) {
      setIsLoaded(false);
      setBackgroundAnimationUrl(url);
    }
  }, [url]);

  return (
    <MediaArtContainer>
      <Image
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: isLoaded ? 1 : 0,
        }}
        src={url ?? default_album_art}
        onError={() => {
          setIsLoaded(true);
          setBackgroundAnimationUrl(default_album_art);
        }}
        onLoad={() => {
          setIsLoaded(true);
        }}
      />
    </MediaArtContainer>
  );
}

const MediaArtContainer = styled.div``;

const Image = styled(motion.img)`
  width: 256px;
  height: 256px;
  border-radius: 10px;
`;
