import React, { useRef } from 'react';
import styled from '@emotion/styled';

import { curveCatmullRom } from '@visx/curve';
import { LinearGradient } from '@visx/gradient';
import { AreaClosed, LinePath } from '@visx/shape';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { Group } from '@visx/group';
import { GridRows } from '@visx/grid';

import { round } from '../../lib/math';
import { getDate, getValue } from './chartUtils';
import { resolutionFormats } from '../../containers/insights/resolution-select/resolutions';

import { useI18n } from '../../hooks/useI18nFormatters';
import { useScales } from './useScales';

import { theme } from '../../theme/theme';

import { ChartTooltip } from './ChartTooltip';
import { ChartTooltipProvider } from './ChartTooltipProvider';

const defaultValues = [];
const margin = {
  top: 40,
  right: 30,
  bottom: 40,
  left: 70,
};

export function Chart(props) {
  const refs = useRef({});
  const { i18n } = useI18n();

  const xMax = props.width - margin.left - margin.right;
  const yMax = props.height - margin.top - margin.bottom;

  const values = props.data?.values ?? defaultValues;
  const minValue = props.data?.minValue ?? 0;
  const maxValue = props.data?.maxValue ?? 0;

  const { timeScale, valueScale } = useScales({
    xMax,
    yMax,
    minValue,
    maxValue,
    values,
  });

  // placeholder to make sure the chart doesn't shift over the page
  if (props.data == null) return <div style={{ height: props.height }} />;
  const baseLog = props.defaultLog ?? {};

  function leftTickFormat(value, index) {
    const absoluteValue = Math.abs(value);

    switch (true) {
      case absoluteValue >= 1000:
        return `${round(value / 1000, 1)}k`;
      case absoluteValue === 0:
        return 0;
      case absoluteValue < 0.001:
        return value.toExponential(1);
      default:
        return round(value, baseLog.decimals);
    }
  }

  function bottomTickFormat(value, index) {
    return tickFormat({
      value,
      index,
      timeFormat: resolutionFormats[props.resolutionId],
      formatter: i18n.dateFormatter.format,
    });
  }

  return (
    <ChartTooltipProvider>
      <Chart.Svg width={props.width} height={props.height}>
        <Group left={margin.left} top={margin.top}>
          <GridRows
            pointerEvents="none"
            top={0}
            left={0}
            scale={valueScale}
            width={xMax}
            height={yMax}
            stroke={theme.color.line}
            strokeDasharray="4 4"
            strokeOpacity={0.5}
            numTicks={4}
          />
          <AxisLeft
            scale={valueScale}
            numTicks={4}
            stroke={theme.color.line}
            tickLength={3}
            tickStroke={theme.color.line}
            tickLabelProps={leftTickLabelProps}
            tickFormat={leftTickFormat}
            label={baseLog?.units}
            labelOffset={36}
            labelProps={leftLabelProps}
          />
          <AxisBottom
            top={yMax}
            scale={timeScale}
            numTicks={props.width > 520 ? 8 : 4}
            hideZero
            stroke={theme.color.line}
            tickLength={3}
            tickStroke={theme.color.line}
            tickFormat={bottomTickFormat}
            tickLabelProps={bottomTickLabelProps}
          />
          {Object.entries(props.logsByKey).map(([key, log]) => {
            const isLoaded = props.data.valuesKeys[key];
            const gradientOpacity = 0.3;

            let color = props.colorMap[key] ?? {};
            let opacity = 1;

            if (props.highlightLogKey != null && key !== props.highlightLogKey) {
              opacity = 0.2;
              color = {
                hsl: theme.color.line,
              };
            }

            if (isLoaded !== true) return null;

            return (
              <React.Fragment key={key}>
                <Chart.LinearGradient
                  id={`gradient:${key}`}
                  from={color.hsl}
                  fromOpacity={gradientOpacity}
                  to={color.hsl}
                  toOffset="100%"
                  toOpacity={0}
                />
                <Chart.LinePath
                  innerRef={(ref) => {
                    refs.current[key] = ref;
                  }}
                  pointerEvents="none"
                  data={props.data.values}
                  x={(d) => {
                    return timeScale(getDate(d));
                  }}
                  y={(d) => {
                    return valueScale(getValue(d, key));
                  }}
                  defined={(d) => {
                    const value = getValue(d, key);
                    return value != null;
                  }}
                  shapeRendering="geometricPrecision"
                  strokeWidth={2}
                  stroke={color.hsl}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  opacity={opacity}
                  curve={curveCatmullRom}
                />
                <Chart.AreaClosed
                  pointerEvents="none"
                  data={props.data.values}
                  x={(d) => {
                    return timeScale(getDate(d));
                  }}
                  y={(d) => {
                    return valueScale(getValue(d, key));
                  }}
                  defined={(d) => {
                    const value = getValue(d, key);
                    return value != null;
                  }}
                  yScale={valueScale}
                  shapeRendering="geometricPrecision"
                  stroke="transparent"
                  fill={`url(#gradient:${key})`}
                  curve={curveCatmullRom}
                />
              </React.Fragment>
            );
          })}
          <ChartTooltip
            parentOffsetX={margin.left}
            valueScale={valueScale}
            timeScale={timeScale}
            values={props.data?.values}
            colorMap={props.colorMap}
            logsByKey={props.logsByKey}
            xMax={xMax}
            yMax={yMax}
            getDate={getDate}
            getValue={getValue}
            refs={refs}
            dateFormatter={i18n.dateFormatter}
          />
        </Group>
      </Chart.Svg>
    </ChartTooltipProvider>
  );
}

function tickFormat({ value, index, timeFormat, formatter }) {
  switch (timeFormat) {
    case 'time':
      return formatter(value, index === 0 ? 'MMM dd' : 'HH:mm');
    case 'date':
      return formatter(value, index === 0 ? 'yyyy' : 'MMM dd');
    case 'month':
      return formatter(value, index === 0 ? 'yyyy' : 'MMM');
    default:
      return formatter(value, 'HH:mm');
  }
}

function bottomTickLabelProps(value, index) {
  return {
    dy: 6,
    textAnchor: 'middle',
    fontSize: '12px',
    fill: theme.color.text_light,
  };
}

function leftTickLabelProps(value, index) {
  return {
    dx: -4,
    dy: 3,
    textAnchor: 'end',
    fontSize: '12px',
    fill: theme.color.text_light,
  };
}

const leftLabelProps = {
  dx: -4,
  textAnchor: 'middle',
  fontSize: '12px',
  fill: theme.color.text_light,
};

Chart.Svg = styled.svg`
  position: relative;
  display: block;
  max-width: 100%;
`;

Chart.LinePath = styled(LinePath)`
  transition: all ease-in-out 350ms;
`;

Chart.AreaClosed = styled(AreaClosed)`
  transition: all ease-in-out 350ms;
`;

Chart.LinearGradient = styled(LinearGradient)`
  transition: all ease-in-out 350ms;
`;
