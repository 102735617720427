import { createNamedProxy, accessorHandler } from "../utils";
import { blendHexColorWithBackground } from './components/helpers';

/**
 * Colors of the Homey Design System
 *
 * @description
 * Rules:
 * - use '_o_05' to describe a opacity color
 * - use '_05' to describe a tint
 *
 * - use hex color for fixed colors
 * - use rgb / rgba color when the color can be used for different opacity variants
 * - use hsl colors when you want to make a color darker / lighter
 *
 */
export const color = {
  /**
   *  Monochrome Palette
   */
  white                   : '#ffffff',
  mono_000                : '#ffffff',
  mono_010                : '#FBFBFD',
  mono_020                : '#F1F2F6',
  mono_025                : '#F4F4FA',
  mono_050                : '#EAEAF1',
  mono_100                : '#DADAE2',
  mono_150                : '#D1D2D5',
  mono_200                : '#C8C8D0',
  mono_300                : '#B1B1B9',
  mono_400                : '#93939A',
  mono_500                : '#7A7A7F',
  mono_600                : '#626265',
  mono_700                : '#49494A',
  mono_800                : '#303031',
  mono_900                : '#1C1C1C',
  mono_950                : '#0C0C0C',
  mono_1000               : '#000000',
  black                   : '#000000',
  // mono opacity
  mono_o_02               : 'rgba(0, 0, 0, .02)',
  mono_o_10               : 'rgba(0, 0, 0, .1)',
  mono_o_20               : 'rgba(0, 0, 0, .2)',
  mono_o_40               : 'rgba(0, 0, 0, .4)',
  mono_o_50               : 'rgba(0, 0, 0, .5)',
  // white opacity
  white_o_0               : 'rgba(255,255,255,0)',
  white_o_50              : 'rgba(255,255,255,.50)',
  white_o_75              : 'rgba(255,255,255,.75)',
  white_o_100             : 'rgba(255,255,255,1)',
  // black opacity
  black_o_0               : 'rgba(0,0,0,0.0)',
  black_o_10              : 'rgba(0,0,0,0.1)',
  black_o_30              : 'rgba(0,0,0,0.3)',
  black_o_50              : 'rgba(0,0,0,0.5)',
  black_o_60              : 'rgba(0,0,0,0.6)',
  black_o_70              : 'rgba(0,0,0,0.7)',
  black_o_80              : 'rgba(0,0,0,0.8)',
  // exceptions
  mono_ex030              : '#F1F1F8',

  /**
   * Color Palette
   */
  ice_white               : '#F4F4FA',
  night_black             : '#121319',

  /* Blue Palette */
  blue                    : '#0082FA', // 500
  blue_hover              : '#339BFB', // 400
  blue_active             : '#0068C8', // 600

  blue_050                : '#E6F3FF',
  blue_100                : '#CCE6FE',
  blue_200                : '#99CDFD',
  blue_300                : '#66B4FC',
  blue_400                : '#339BFB',
  blue_500                : '#0082FA',
  blue_600                : '#0068C8',
  blue_700                : '#004E96',
  blue_800                : '#003464',
  blue_900                : '#001A32',

  blue_o_05               : 'rgba(0, 130, 250, 0.05)',
  blue_o_08               : 'rgba(0, 130, 250, 0.08)',
  blue_o_10               : 'rgba(0, 130, 250, 0.10)',
  blue_o_14               : 'rgba(0, 130, 250, 0.14)',
  blue_o_20               : 'rgba(0, 130, 250, 0.20)',
  blue_o_30               : 'rgba(0, 130, 250, 0.30)',
  blue_o_50               : 'rgba(0, 130, 250, 0.50)',

  blue_o_05_mono_000_b    : blendHexColorWithBackground({ hexColor: '#0082FA', hexBackgroundColor: "#FFFFFF", alpha: 0.05 }),
  blue_o_10_mono_000_b    : blendHexColorWithBackground({ hexColor: '#0082FA', hexBackgroundColor: "#FFFFFF", alpha: 0.1 }),

  __todo__blue_g_0                : `linear-gradient(270deg, rgb(0, 191, 255), rgb(0, 106, 255))`, // @todo deprecated -> see gradient_blue_...
  __todo__blue_g_1                : `linear-gradient(270deg, rgb(25, 198, 255), rgb(26, 121, 255))`, // @todo deprecated -> see gradient_blue_...
  __todo__blue_g_2                : `linear-gradient(270deg, rgb(0, 172, 230), rgb(0, 96, 230))`, // @todo deprecated -> see gradient_blue_...

  /* Green Palette */
  green                   : '#3FC700', // 500
  green_hover             : '#65D233', // 400
  green_active            : '#329F00', // 600

  green_050               : '#ECFAE6',
  green_100               : '#D9F4CC',
  green_200               : '#B2E999',
  green_300               : '#8CDD66',
  green_400               : '#65D233',
  green_500               : '#3FC700',
  green_600               : '#329F00',
  green_700               : '#267700',
  green_800               : '#195000',
  green_900               : '#0D2800',

  /* Red Palette */
  red                     : '#D81C1D', // 500
  red_hover               : '#E0494A', // 400
  red_active              : '#AD1617', // 600

  red_050                 : "#FCE9E9",
  red_100                 : '#F7D2D2',
  red_200                 : '#EFA4A5',
  red_300                 : '#E87777',
  red_400                 : '#E0494A',
  red_500                 : '#D81C1D',
  red_600                 : '#AD1617',
  red_700                 : '#821111',
  red_800                 : '#560B0C',
  red_900                 : '#2B0606',

  red_o_05                : 'rgba(216, 28,  29, 0.05)',
  red_o_08                : 'rgba(216, 28,  29, 0.08)',
  red_o_14                : 'rgba(216, 28,  29, 0.14)',
  red_o_20                : 'rgba(216, 28,  29, 0.20)',
  red_o_50                : 'rgba(216, 28,  29, 0.50)',
  red_o_80                : 'rgba(216, 28,  29, 0.80)',

  /* Yellow Palette */
  yellow                  : '#F5B207', // 500
  yellow_hover            : '#F9C43E', // 400
  yellow_active           : '#E9A907', // 600

  yellow_025              : '#FEFBF2',
  yellow_050              : '#FEF7E6',
  yellow_100              : '#FDF0CD',
  yellow_200              : '#FBE09C',
  yellow_300              : '#F9D16A',
  yellow_400              : '#F7C139',
  yellow_500              : '#F5B207',
  yellow_600              : '#C48E06',
  yellow_700              : '#936B04',
  yellow_800              : '#624703',
  yellow_900              : '#312401',

  yellow_o_05             : 'rgba(245, 178,  7, 0.05)',

  /* Orange Palette */
  orange                  : '#FF9713',
  orange_0_20             : 'rgba(255, 151,  19, 0.20)',

  /**
   * Special Palette
   * @description those are color exceptions with a special purpose
   */
  special_favorite        : `#FF0000`,
  special_beta            : `#FA6400`,

  /**
   * Gradient Palette
   */
  // Blue
  gradient_blue_active     : '#005cd5',
  gradient_blue_start      : '#367CFF',
  gradient_blue_end        : '#52baff',
  gradient_blue_hover      : '#52DEFF',
  // Pink
  gradient_pink_active     : '#c70076',
  gradient_pink_start      : '#E90090',
  gradient_pink_end        : '#FC6767',
  gradient_pink_hover      : '#FC6767',

  /**
   * Note Palette
   */
  note_yellow                 : '#FEF49C',
  note_yellow_selected        : '#F8EE97',
  note_yellow_focus           : '#FDF4A5',
  note_yellow_border          : '#B0A96C',
  note_red                    : '#FFC7C7',
  note_red_selected           : '#FAC3C3',
  note_red_focus              : '#FFCCCC',
  note_red_border             : '#B28A8A',
  note_green                  : '#B2FFA0',
  note_green_selected         : '#AEFA9C',
  note_green_focus            : '#B9FFA9',
  note_green_border           : '#7CB26F',
  note_blue                   : '#ADF4FF',
  note_blue_selected          : '#A9EFFA',
  note_blue_focus             : '#B5F5FF',
  note_blue_border            : '#78AAB2',

  /**
   * Logic Palette
   */
  logic_boolean           : '#FA6400',
  logic_boolean_hover     : '#FF7B24',
  logic_boolean_active    : '#E65C00',
  logic_image             : '#B620E0',
  logic_image_hover       : '#BF3AE4',
  logic_image_active      : '#AB1DD2',
  logic_number            : '#2FB0FF',
  logic_number_hover      : '#4DBBFF',
  logic_number_active     : '#14A6FF',
  logic_string            : '#75D600',
  logic_string_hover      : '#79EB00',
  logic_string_active     : '#66C700',
  logic_error             : '#D81C1D', // equal to `red`
  logic_error_hover       : '#EA2425', // equal to `red_hover`
  logic_error_active      : '#C2191A', // equal to `red_active`

  system_account                 : "#F3AC16",
  system_alarms                  : "#E3292F",
  system_alexa                   : "#00CAFF",
  system_api_keys                : "#452FB2",
  system_appearance              : "#000000",
  system_apps                    : "#7538BC",
  system_audio                   : "#FF491E",
  system_backups                 : "#00AEF5",
  system_bateries                : "#00C900",
  system_coprocessor             : "#353535",
  system_dashboards              : "#457CE0",
  system_datetime                : "#E3282F",
  system_devices                 : "#7243E1",
  system_energy                  : "#10C56E",
  system_experiments             : "#387868",
  system_family                  : "#FF7F33",
  system_feedback                : "#2FB2DB",
  system_flow                    : "#3BCB18",
  system_general                 : "#454545",
  system_google                  : "#EB4139",
  system_home                    : "#494949",
  system_homey_bridge            : "#2F2F2F",
  system_homeyscript             : "#2C1E32",
  system_insights                : "#452D76",
  system_language                : "#00C1BE",
  system_ledring                 : "#F79128",
  system_location                : "#7CD516",
  system_logic                   : "#2AA733",
  system_moods                   : "#5C5AD7",
  system_more                    : "#454545",
  system_premium                 : "#F4317B",
  system_presence                : "#ADE127",
  system_push_notifications      : "#235B7D",
  system_reminders               : "#E01310",
  system_review                  : "#EF0048",
  system_safety                  : "#EF1965",
  system_satelite                : "#6D2FC7",
  system_security                : "#495964",
  system_settings                : "#343434",
  system_siri_shortcuts          : "#1C1F56",
  system_speech                  : "#14BDD8",
  system_support                 : "#3C85C3",
  system_timeline_notifications  : "#FFCD00",
  system_updates                 : "#D20BCB",
  system_zwave                   : "#20365E",
  system_zigbee                  : "#E82249",
  system_zone                    : "#494949",
} as const

export const proxy: typeof color = createNamedProxy('color', color, accessorHandler);
