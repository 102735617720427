import { forwardRef, useRef } from 'react';
import styled from '@emotion/styled';
import { useButton, AriaButtonProps } from 'react-aria';

import { mergeRefs } from '../../lib/mergeRefs';

import { su } from '../../theme/functions/su';
import { theme } from '../../theme/theme';

import { ButtonIcon } from './ButtonIcon';

interface Props extends AriaButtonProps<'button'> {
  className?: string;
  style?: Object;
  title?: string;
  form?: string;
  /** Whether the element box shadow should be removed. */
  styleFlat?: boolean;
  /** Whether the element padding should be decreased. */
  styleCompact?: boolean;
  /** Whether the element should receive width 100% style. */
  styleWidth?: 'full';
}

interface CompoundedComponent
  extends React.ForwardRefExoticComponent<Props & React.RefAttributes<HTMLButtonElement>> {
  S: typeof S;
  Icon: typeof ButtonIcon;
  Text: typeof Text;
}

export const GradientButton = forwardRef((props, forwardedRef) => {
  const ref = useRef<HTMLButtonElement>(null);
  const button = useButton(props, ref);

  // @Harwin Borger no disabled state implemented

  return (
    <S.Root
      {...button.buttonProps}
      ref={mergeRefs([ref, forwardedRef])}
      className={props.className}
      style={props.style}
      as={props.elementType}
      title={props.title}
      form={props.form}
      data-style-flat={props.styleFlat}
      data-style-compact={props.styleCompact}
      data-style-width={props.styleWidth}
      data-is-disabled={props.isDisabled}
      data-is-pressed={button.isPressed}
    >
      {props.children}
    </S.Root>
  );
}) as CompoundedComponent;

const Text = styled.span`
  white-space: nowrap;
`;

GradientButton.Icon = ButtonIcon;
GradientButton.Text = Text;

function S() {}
GradientButton.S = S;

S.Root = styled.button`
  position: relative;
  display: grid;
  justify-content: center;
  grid-auto-flow: column;
  grid-gap: 8px;
  align-items: center;
  padding: ${su(1)} ${su(2)};
  line-height: ${su(2)};
  min-height: ${su(4)};
  background: ${theme.gradientButton.background_color_blue};
  background-size: ${theme.gradientButton.background_size};
  background-position: ${theme.gradientButton.background_position_default};
  color: ${theme.color.text_white};
  border-radius: ${theme.borderRadius.default};
  box-shadow: ${theme.boxShadow.default};
  font-weight: ${theme.fontWeight.medium};
  transition: ${theme.duration.fast} ${theme.curve.fastIn};
  transition-property: background-position, transform, box-shadow;
  text-decoration: none;
  outline: none;

  // States
  &:hover {
    background-position: ${theme.gradientButton.background_position_hover};
    box-shadow: ${theme.boxShadow.default_hover};
  }

  &:not([data-is-disabled='true']) {
    &[data-is-pressed='true']:hover {
      background-position: ${theme.gradientButton.background_position_active};
      box-shadow: ${theme.boxShadow.default_active};
      transform: scale(0.96875);
    }
  }

  &[data-is-disabled='true'] {
    background: ${theme.color.text_disabled};
    cursor: not-allowed;
  }

  // Shadow variants
  &[data-style-flat='true'] {
    box-shadow: none;
  }

  // Size variants
  &[data-style-compact='true'] {
    grid-gap: 6px;
    padding-left: ${su(1.5)};
    padding-right: ${su(1.5)};
  }

  // Width variants
  &[data-style-width='full'] {
    width: 100%;
  }
`;
