import { __DEV__ } from '../../lib/__dev__';
import { BaseApiStore } from '../BaseApiStore';

export class FlowFolderStore extends BaseApiStore {
  static key = 'flowFolders';
  static store = this.createStore(this.key);
  static cacheId = 'HomeyAPI.ManagerFlow.FlowFolder';
  static rootFolderId = null;
  static tmpFolderId = 'tmp-folder';

  static createInitialState() {
    return {
      data: null,
      byId: null,
      loading: true,
      error: null,
      manager: null,
    };
  }

  static async fetchData() {
    __DEV__ && console.info('fetch:flowFolders');
    this.destroy();
    const state = this.get();

    this.set({
      ...this.createInitialState(),
    });

    try {
      const managerFlow = state.api.flow;

      const waitPromise = new Promise((resolve) => setTimeout(resolve, 0));
      const flowFoldersPromise = await managerFlow.getFlowFolders();

      const [, data] = await Promise.all([waitPromise, flowFoldersPromise]);

      const result = Object.values(data).reduce(
        (accumulator, flowFolder, index) => {
          accumulator.data[flowFolder.id] = flowFolder;
          accumulator.byId[flowFolder.id] = { ...flowFolder };
          return accumulator;
        },
        {
          data: {},
          byId: {},
        }
      );

      result.data[FlowFolderStore.rootFolderId] = {
        id: FlowFolderStore.rootFolderId,
        parent: null,
        name: 'Flows',
      };

      result.byId[FlowFolderStore.rootFolderId] = {
        id: FlowFolderStore.rootFolderId,
        parent: null,
        name: 'Flows',
      };

      this.set({
        ...this.createInitialState(),
        loading: false,
        data: result.data,
        byId: result.byId,
        manager: managerFlow,
      });

      managerFlow.addListener('flowfolder.create', this.handleCreate);
      managerFlow.addListener('flowfolder.update', this.handleUpdate);
      managerFlow.addListener('flowfolder.delete', this.handleDelete);
    } catch (error) {
      this.destroy();
      console.error(error);

      this.set({
        ...this.createInitialState(),
        loading: false,
        error,
      });
    }
  }

  static destroy() {
    __DEV__ && console.info('destroy:flowFolders');
    const { api } = this.get();

    clearTimeout(this.processBatchTimeout);
    this.processBatchTimeout = null;
    this.updateQueue = {};

    if (api) {
      const { flow } = api;
      flow.removeListener('flowfolder.create', this.handleCreate);
      flow.removeListener('flowfolder.update', this.handleUpdate);
      flow.removeListener('flowfolder.delete', this.handleDelete);
    }
  }

  static handleCreate = (createdFlowFolder) => {
    __DEV__ && console.info(`create:flowFolder:${createdFlowFolder.id}`);
    const state = this.get();
    // read sync from the cache
    const flowFolderInstance = state.api.flow._caches[this.cacheId].getOne({
      id: createdFlowFolder.id,
    });

    if (flowFolderInstance == null) return;

    this.set({
      data: {
        ...state.data,
        [createdFlowFolder.id]: flowFolderInstance,
      },
      byId: {
        ...state.byId,
        [createdFlowFolder.id]: { ...createdFlowFolder },
      },
    });
  };

  static processBatchTimeout = null;
  static updateQueue = {};
  static handleUpdate = (updatedFlowFolder) => {
    __DEV__ && console.info(`update:flowFolder:${updatedFlowFolder.id}`);
    this.updateQueue[updatedFlowFolder.id] = {
      ...updatedFlowFolder,
    };

    if (this.processBatchTimeout == null) {
      this.processBatchTimeout = setTimeout(() => {
        const state = this.get();
        const queuedFlowFolders = this.updateQueue;
        this.updateQueue = {};
        this.processBatchTimeout = null;

        const nextData = {
          ...state.data,
        };

        const nextById = {
          ...state.byId,
          ...queuedFlowFolders,
        };

        this.set({
          data: nextData,
          byId: nextById,
        });
      }, 200);
    }
  };

  static handleDelete = (deletedFlow) => {
    __DEV__ && console.info(`delete:flowFolder:${deletedFlow.id}`);
    const state = this.get();
    const flowFolderInstance = state.data[deletedFlow.id];

    // should not be possible TODO: sentry
    if (flowFolderInstance == null) return;

    const nextData = { ...state.data };
    const nextById = { ...state.byId };

    delete nextData[deletedFlow.id];
    delete nextById[deletedFlow.id];

    this.set({
      data: nextData,
      byId: nextById,
    });
  };

  static setFolderParent({ folderId, parentId }) {
    const state = this.get();
    const prevById = state.byId;

    const nextFolder = {
      ...state.byId[folderId],
      parent: parentId,
    };

    const nextById = {
      ...state.byId,
      [folderId]: nextFolder,
    };

    this.set({
      byId: nextById,
    });

    return state.manager
      .updateFlowFolder({
        id: folderId,
        flowfolder: nextFolder,
      })
      .catch((error) => {
        this.set({
          byId: {
            ...prevById,
          },
        });

        throw error;
      });
  }

  static createTempFolder({ parentId }) {
    const state = this.get();

    const newFolder = {
      id: this.tmpFolderId,
      name: '',
      parent: parentId,
    };

    const nextById = {
      ...state.byId,
      [newFolder.id]: newFolder,
    };

    this.set({
      byId: nextById,
    });
  }

  static saveTempFolder({ name }) {
    const state = this.get();

    const newFolder = {
      ...state.byId[this.tmpFolderId],
      name: name,
    };

    const nextById = {
      ...state.byId,
      [newFolder.id]: newFolder,
    };

    this.set({
      byId: nextById,
    });

    state.manager.removeListener('flowfolder.create', this.handleCreate);
    return state.manager
      .createFlowFolder({
        flowfolder: {
          name: newFolder.name,
          parent: newFolder.parent,
        },
      })
      .then(async (createdFolder) => {
        const folder = await state.manager
          .getFlowFolder({
            id: createdFolder.id,
          })
          .catch(console.error);

        const nextData = {
          ...state.data,
          [folder.id]: folder,
        };

        const nextById = {
          ...state.byId,
          [folder.id]: { ...folder },
        };

        delete nextById[newFolder.id];

        this.set({
          data: nextData,
          byId: nextById,
        });
      })
      .catch((error) => {
        const state = this.get();

        const nextById = {
          ...state.byId,
        };

        delete nextById[newFolder.id];

        this.set({
          byId: nextById,
        });

        throw error;
      })
      .finally(() => {
        state.manager.addListener('flowfolder.create', this.handleCreate);
      });
  }

  static deleteTempFolder() {
    const state = this.get();

    const { [this.tmpFolderId]: value, ...rest } = state.byId;

    const nextById = {
      ...rest,
    };

    this.set({
      byId: nextById,
    });
  }

  static deleteFolder({ folderId }) {
    const state = this.get();

    const { [folderId]: prevFolder, ...nextById } = state.byId;

    this.set({
      byId: { ...nextById },
    });

    return state.manager
      .deleteFlowFolder({
        id: folderId,
      })
      .catch((error) => {
        const state = this.get();

        const nextById = {
          ...state.byId,
          [folderId]: prevFolder,
        };

        this.set({
          byId: {
            ...nextById,
          },
        });

        throw error;
      });
  }

  static saveFolderName({ folderId, name }) {
    const state = this.get();
    const prevName = state.byId[folderId].name;

    const nextById = {
      ...state.byId,
      [folderId]: {
        ...state.byId[folderId],
        name,
      },
    };

    this.set({
      byId: nextById,
    });

    return state.manager
      .updateFlowFolder({
        id: folderId,
        flowfolder: {
          name,
        },
      })
      .catch((error) => {
        const state = this.get();

        const nextById = {
          ...state.byId,
          [folderId]: {
            ...state.byId[folderId],
            name: prevName,
          },
        };

        this.set({
          byId: nextById,
        });

        throw error;
      });
  }
}
