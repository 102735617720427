import { useState, useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import styled from '@emotion/styled';

import { RouteManager } from '../../RouteManager';

import { useLocalStorageState } from '../../hooks/useLocalStorageState';
import { useQuery } from '../../hooks/useQuery';
import { useApi } from '../../store/HomeyStore';
import { useMount } from '../../hooks/useMount';
import { useEffectEvent } from '../../hooks/useEffectEvent';
import { useI18n } from '../../hooks/useI18nFormatters';
import { useLayoutContextState } from '../../LayoutContext';
import { useDevicesAttach } from '../../store/devices/useDevices';
import { useLogsAttach } from '../../store/insights/useInsights';
import { useFlowsAttach } from '../../store/flow/useFlows';
import { useMoodsAttach } from '../../store/moods/useMoods';
import { useZonesAttach } from '../../store/zones/useZones';
import { useAdvancedFlowsAttach } from '../../store/advanced-flow/useAdvancedFlows';

import { Scroll } from '../../components/common/Scroll';
import { Layout } from '../../Layout';
import { ZonesView } from './ZonesView';
import { TableView } from './table/TableView';
import { ZoneNavigation } from './zone-navigation/ZoneNavigation';
import { DeviceSettingsDialog } from './settings/DeviceSettingsDialog';
import { Controls } from '../../components/controls/Controls';
import { PairingDelegator } from '../../components/pairing/PairingDelegator';
import { Modus, ModusButton } from '../../components/common/Modus';
import { DeviceTile } from '../../components/device/DeviceTile';
import { PremiumRequiredDialog } from '../../components/premium/PremiumRequiredDialog';

import { iconGridViewMedium } from '../../theme/icons/interface/grid-view-medium/iconGridViewMedium';
import { iconGridViewLarge } from '../../theme/icons/interface/grid-view-large/iconGridViewLarge';
import { iconListView } from '../../theme/icons/interface/list-view/iconListView';

export function DevicesPage() {
  const { i18n } = useI18n();
  const location = useLocation();
  const mount = useMount();

  const { api } = useApi();
  useDevicesAttach();
  useZonesAttach();
  useMoodsAttach();
  useLogsAttach();
  useFlowsAttach();
  useAdvancedFlowsAttach();

  const { layoutContextState } = useLayoutContextState();

  const deviceMatch = useRouteMatch('/homeys/:homeyId/devices/:deviceId');
  const deviceId = deviceMatch?.params?.deviceId || null;

  const params = useQuery();
  const dialogType = params.get('dialog');
  const dialogResourceKey = params.get('key');

  const [selectedZoneId, setSelectedZoneId] = useState(null);
  const [selectedDeviceId, setSelectedDeviceId] = useState(deviceId);
  const [tileSize, setTileSize] = useLocalStorageState(DeviceTile.size.medium, 'deviceTileSize');

  useEffect(() => {
    return function () {
      if (mount.isMounted) {
        setSelectedZoneId(null);
        setSelectedDeviceId(null);
      }
    };
  }, [api, mount]);

  useEffect(() => {
    setSelectedDeviceId(deviceId);
  }, [deviceId]);

  const handleControlsClick = useEffectEvent((event, device) => {
    if (selectedDeviceId === device.id) {
      RouteManager.toDevices();
      return;
    }

    RouteManager.toDevice(device.id);
  });

  function onModusPress(value) {
    setTileSize(value);
  }

  return (
    <Layout
      isRightOpen={selectedDeviceId != null}
      subNavigationProps={{
        key: 'devices',
        width: 280,
        title: i18n.messageFormatter('navigation.devices'),
      }}
      subNavigationChildren={
        <ZoneNavigation selectedZoneId={selectedZoneId} setSelectedZoneId={setSelectedZoneId} />
      }
      actions={
        <Modus>
          <ModusButton
            onPress={() => onModusPress(null)}
            isActive={tileSize === null}
            url={iconListView}
          />
          <ModusButton
            onPress={() => onModusPress(DeviceTile.size.medium)}
            isActive={tileSize === DeviceTile.size.medium}
            url={iconGridViewMedium}
          />
          <ModusButton
            onPress={() => onModusPress(DeviceTile.size.large)}
            isActive={tileSize === DeviceTile.size.large}
            url={iconGridViewLarge}
          />
        </Modus>
      }
      content={(() => {
        if (tileSize == null) {
          return (
            <TableView
              selectedZoneId={selectedZoneId}
              selectedDeviceId={selectedDeviceId}
              onControlsClick={handleControlsClick}
            />
          );
        }

        return (
          <Scroll
            flex={true}
            style={{
              '--scrollbar-thumb-background': layoutContextState.scrollbarThumbBackgroundMV,
            }}
          >
            <S.Root>
              <ZonesView
                selectedZoneId={selectedZoneId}
                selectedDeviceId={selectedDeviceId}
                tileSize={tileSize}
                onControlsClick={handleControlsClick}
              />
            </S.Root>
          </Scroll>
        );
      })()}
      right={<Controls deviceId={selectedDeviceId} onClosePress={() => RouteManager.toDevices()} />}
    >
      <AnimatePresence>
        {dialogType === RouteManager.dialogRoute.deviceSettings && (
          <DeviceSettingsDialog deviceId={dialogResourceKey} />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {location.state?.pairDialog != null && (
          <PairingDelegator state={location.state.pairDialog} zoneId={selectedZoneId} />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {location.state?.premiumRequiredDialog === true && <PremiumRequiredDialog />}
      </AnimatePresence>
    </Layout>
  );
}

const S = (DevicesPage.S = class S {
  static Root = styled.section`
    display: flex;
    flex: 1 1 auto;
    padding: 0 30px 20px;
  `;
});
