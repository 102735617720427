import { useRef } from 'react';

import { useResizeObserver } from '../../../hooks/useResizeObserver';
import { css } from '@emotion/react';
import { su } from '../../../theme/functions/su';

/**
 * @param scrollContainerRef
 * @param scrollContainerChildRef
 */
export function useScrollContainerFade({ scrollContainerRef, scrollContainerChildRef }) {
  const timeout = useRef();

  function setFadeCompositionProperties() {
    const scrollContainerElement = scrollContainerRef.current;
    const isHorizontalScroll =
      scrollContainerElement.scrollWidth > scrollContainerElement.offsetWidth;
    const isVerticalScroll =
      scrollContainerElement.scrollHeight > scrollContainerElement.offsetHeight;
    const width = scrollContainerElement.offsetWidth;
    const height = scrollContainerElement.offsetHeight;

    scrollContainerElement.setAttribute('data-is-scrollbar-horizontal', isHorizontalScroll);
    scrollContainerElement.setAttribute('data-is-scrollbar-vertical', isVerticalScroll);
    scrollContainerElement.style.setProperty('--scroll-container-width', `${width}px`);
    scrollContainerElement.style.setProperty('--scroll-container-height', `${height}px`);
  }

  function temporaryDisableFadeWhileResizing() {
    function disabledFade() {
      scrollContainerElement.setAttribute('data-is-fade-disabled', true);
    }

    function enableFade() {
      scrollContainerElement.setAttribute('data-is-fade-disabled', false);
    }

    const scrollContainerElement = scrollContainerRef.current;

    disabledFade();
    clearTimeout(timeout.current);
    timeout.current = setTimeout(enableFade, 100);
  }

  /**
   * Check if the size of the scroll container changed:
   * - Sidebar size is changed
   * - Window resize
   */
  useResizeObserver({
    ref: scrollContainerRef,
    onResize: () => {
      setFadeCompositionProperties();
      temporaryDisableFadeWhileResizing();
    },
  });

  /**
   * Check if the size of the scroll container content changed:
   * - When you move nodes
   */
  useResizeObserver({
    ref: scrollContainerChildRef,
    onResize: () => {
      setFadeCompositionProperties();
      temporaryDisableFadeWhileResizing();
    },
  });
}

export const scrollContainerMask = css`
  --scrollbar-width: ${su(2)};
  --scroll-container-fade-size: ${su(4)};

  /**
   * Mask edges of drawable canvas
   * todo gradient are not smooth enough, see possible solution on: https://stackoverflow.com/questions/13151331/smooth-css-gradients
   */

  &:not([data-is-fade-disabled='true']) {
    /**
     * Mask edges of drawable canvas
     * Safari 15.4+ & Mozilla Firefox solution
     */
    @supports (mask-composite: intersect) {
      --scroll-container-fade-offset-top: var(--scroll-container-fade-size);
      --scroll-container-fade-offset-left: var(--scroll-container-fade-size);
      --scroll-container-fade-offset-right: calc(
        var(--scroll-container-width, 100%) - var(--scroll-container-fade-size) -
          var(--scrollbar-width)
      );
      --scroll-container-fade-offset-bottom: calc(
        var(--scroll-container-height, 100%) - var(--scroll-container-fade-size) -
          var(--scrollbar-width)
      );
      --scroll-container-fade-offset-scrollbar: calc(100% - var(--scrollbar-width));
      --scroll-container-inner-width: calc(var(--scroll-container-width) - var(--scrollbar-width));
      --scroll-container-inner-height: calc(
        var(--scroll-container-height) - var(--scrollbar-width)
      );

      /**
       * Mask-image:
       * - fade vertical direction
       * - fade horizontal direction
       * - vertical scrollbar -> otherwise scrollbar is faded
       * - show horizontal scrollbar -> otherwise scrollbar is faded
       */
      mask-image: linear-gradient(black, black),
        linear-gradient(
          180deg,
          black ${su(0)},
          transparent var(--scroll-container-fade-offset-top),
          transparent var(--scroll-container-fade-offset-bottom),
          black 100%
        ),
        linear-gradient(
          90deg,
          black ${su(0)},
          transparent var(--scroll-container-fade-offset-left),
          transparent var(--scroll-container-fade-offset-right),
          black 100%
        );
      mask-composite: exclude, add;
      mask-repeat: no-repeat;
      mask-size: 100% 100%, var(--scroll-container-inner-width) var(--scroll-container-inner-height),
        var(--scroll-container-inner-width) var(--scroll-container-inner-height);
      mask-position: 0 0, 0 0;

      // toggle to test
      //background: #099;

      &[data-is-scrollbar-horizontal='false'] {
        --scroll-container-fade-offset-bottom: 100%;
      }

      &[data-is-scrollbar-vertical='false'] {
        --scroll-container-fade-offset-right: 100%;
      }

      /**
       * Mozilla Firefox only solution because it has transparent scrollbars
       * Does only show gradient on top and left side. Right and bottom have solid edges
       * todo fix faded scrollbar
       */
      @supports (-moz-appearance: none) {
        --scroll-container-fade-offset-right: 100%;
        --scroll-container-fade-offset-bottom: 100%;
        --scroll-container-fade-offset-scrollbar: 100%;

        /**
         * Mask-image:
         * - fade vertical direction
         * - fade horizontal direction
         */
        mask-image: linear-gradient(
            180deg,
            transparent 0,
            black var(--scroll-container-fade-offset-top),
            black var(--scroll-container-fade-offset-bottom),
            transparent 100%
          ),
          linear-gradient(
            90deg,
            transparent 0,
            black var(--scroll-container-fade-offset-left),
            black var(--scroll-container-fade-offset-right),
            transparent 100%
          );
        mask-composite: intersect;
        mask-size: 100%;

        //background: #990; // toggle to test
      }
    }

    /**
     * Support Chrome and Edge browsers
     * Track support in https://caniuse.com/css-masks
     */
    @supports not (mask-composite: intersect) {
      --scroll-container-fade-vertical-width: calc(100% - ${su(2)});
      --scroll-container-fade-vertical-height: calc(100% - ${su(10)});
      --scroll-container-fade-horizontal-width: calc(100% - ${su(10)});
      --scroll-container-fade-horizontal-height: calc(100% - ${su(2)});
      --scroll-container-fade-height: calc(100% - ${su(2)});
      --scroll-container-fade-corner-size: var(--scroll-container-fade-size)
        var(--scroll-container-fade-size);
      --scroll-container-fade-corner-offset: calc(100% - ${su(2)});

      /**
       * Mask-image:
       * - fade vertical direction
       * - fade horizontal direction
       * - top left corner
       * - top right corner
       * - bottom right corner
       * - bottom left corner
       * - show vertical scrollbar
       * - show horizontal scrollbar
       */
      mask-image: linear-gradient(
          180deg,
          transparent ${su(0)},
          black var(--scroll-container-fade-size),
          black calc(100% - var(--scroll-container-fade-size)),
          transparent 100%
        ),
        linear-gradient(
          90deg,
          transparent ${su(0)},
          black var(--scroll-container-fade-size),
          black calc(100% - var(--scroll-container-fade-size)),
          transparent 100%
        ),
        radial-gradient(ellipse at bottom right, black 0px, transparent ${su(4)}),
        radial-gradient(ellipse at bottom left, black 0px, transparent ${su(4)}),
        radial-gradient(ellipse at top left, black 0px, transparent ${su(4)}),
        radial-gradient(ellipse at top right, black 0px, transparent ${su(4)}),
        linear-gradient(black 0, black ${su(2)}), linear-gradient(black 0, black ${su(2)});
      mask-size: var(--scroll-container-fade-horizontal-width)
          var(--scroll-container-fade-horizontal-height),
        var(--scroll-container-fade-vertical-width) var(--scroll-container-fade-vertical-height),
        var(--scroll-container-fade-corner-size), var(--scroll-container-fade-corner-size),
        var(--scroll-container-fade-corner-size), var(--scroll-container-fade-corner-size),
        ${su(2)} 100%, 100% ${su(2)};
      mask-position: var(--scroll-container-fade-size) 0, 0 var(--scroll-container-fade-size), 0 0,
        var(--scroll-container-fade-corner-offset) 0,
        var(--scroll-container-fade-corner-offset) var(--scroll-container-fade-corner-offset),
        0 var(--scroll-container-fade-corner-offset), 100% 0, 0 100%;
      mask-repeat: no-repeat;

      // toggle to test
      //background: #f09;
      // The corners are still rendered, this makes the implementation not perfect, but good enough.
      &[data-is-scrollbar-horizontal='false'] {
        --scroll-container-fade-vertical-height: 100%;
      }

      // The corners are still rendered, this makes the implementation not perfect, but good enough.
      &[data-is-scrollbar-vertical='false'] {
        --scroll-container-fade-horizontal-width: 100%;
      }
    }
  }
`;
