import { useMemo, useRef } from 'react';
import HomeyLib from 'homey-lib/webpack/index.js';
import styled from '@emotion/styled';
import { useLocale } from 'react-aria';
import semver from 'semver';

import { HomeyImages } from '../../../theme/HomeyImages';

import { useCapabilities, useDevice } from '../../../store/devices/useDevices';
import { useZonesTree } from '../../../store/zones/useZones';
import { useApi } from '../../../store/HomeyStore';
import { useI18n } from '../../../hooks/useI18nFormatters';

import { deviceIconsByCategory } from '../../../theme/HomeyImages';

import { su } from '../../../theme/functions/su';

import { TextField } from '../../../components/forms/TextField';
import { SelectControllable } from '../../../components/forms/select/SelectControllable';

import { DeviceSettingsGeneralIconSelect } from './DeviceSettingsGeneralIconSelect';
import { DeviceSettingsGeneralStatusIndicatorSelect } from './DeviceSettingsGeneralStatusIndicatorSelect';
import { IndicatorAlarmSpecificStatic } from '../../../components/device/IndicatorAlarmSpecificStatic';
import { IndicatorAlarmStatic } from '../../../components/device/IndicatorAlarmStatic';
import { IndicatorBattery } from '../../../components/device/IndicatorBattery';
import { IndicatorNumberStatic } from '../../../components/device/IndicatorNumberStatic';
import {
  dangerCapabilityIdList,
  defaultCapabilityIdOrderList,
} from '../../../components/device/Indicator';

const deviceClasses = HomeyLib.Device.getClasses();

export function DeviceSettingsGeneral(props) {
  const { api } = useApi();
  const { device } = useDevice({ deviceId: props.deviceId });
  const { capabilities } = useCapabilities({ deviceId: props.deviceId });
  const { i18n } = useI18n();
  const { locale } = useLocale();
  const zonesTree = useZonesTree();
  const overlayTargetRef = useRef();

  // Return a nested array of device icon options by category
  const deviceIconOptions = useMemo(() => {
    const options = [
      {
        key: 'default',
        label: 'Default',
        children: [{ key: 'default', textValue: 'default', icon: device?.iconObj }],
      },
    ];

    for (const category of deviceIconsByCategory) {
      options.push({
        key: category.id,
        label: category.label,
        children: category.icons.map((icon) => {
          return {
            key: icon.id,
            textValue: icon.id,
            icon: HomeyImages.getIconForDevice(icon.id),
          };
        }),
      });
    }

    return options;
  }, [device?.iconObj]);

  // Return all capabilities that are of type measure, meter, sensor or alarm.
  const selectableCapabilities = useMemo(() => {
    const selectableCapabilities = [];
    const capabilitiesObj = device?.capabilitiesObj;
    // console.log('capabilitiesObj', capabilitiesObj);
    const alarmCapabilityList = dangerCapabilityIdList;

    // Return none if no capabilities are defined.
    if (capabilitiesObj == null) {
      selectableCapabilities.push({
        id: '.none',
        title: i18n.messageFormatter('common.none'),
        default: true,
      });

      return selectableCapabilities;
    }

    function sortCapabilityImportance(capabilities) {
      const order = defaultCapabilityIdOrderList;

      return capabilities
        .filter((item) => order.includes(item))
        .sort((a, b) => (order.indexOf(a) > order.indexOf(b) ? 1 : -1));
    }

    const defaultDeviceCapabilities = sortCapabilityImportance(Object.keys(capabilitiesObj));

    function returnAlarmCapabilities(capabilities) {
      return capabilities
        .filter((item) => item.startsWith('alarm_'))
        .filter((item) => !alarmCapabilityList.includes(item))
        .filter((item) => !item.startsWith('alarm_battery') && !item.startsWith('measure_battery'));
    }
    const alarmCapabilities = returnAlarmCapabilities(Object.keys(capabilitiesObj));

    if (alarmCapabilities.length >= 1) {
      selectableCapabilities.push({
        id: '.anyAlarm',
        title: i18n.messageFormatter('device.settings.anyAlarm'),
        default: true,
      });
    }

    let batteryCapabilityAdded = false;

    // Iterate over the device capabilities
    for (const [key, item] of Object.entries(capabilitiesObj)) {
      // Skip the 'button' capability
      if (item.id === 'onoff' || item.id === 'button') {
        continue;
      }

      // Check for battery capabilities
      if (key.startsWith('alarm_battery') || key.startsWith('measure_battery')) {
        if (!batteryCapabilityAdded) {
          // Add a special item that groups battery capabilities
          selectableCapabilities.push({
            id: key,
            title: i18n.messageFormatter('device.settings.anyBattery'),
            default: false,
          });
          batteryCapabilityAdded = true;
        }
      }
      // Check for other capabilities
      else if (
        (key.startsWith('measure_') || key.startsWith('meter_') || key.startsWith('alarm_')) &&
        !alarmCapabilityList.includes(key) &&
        (item.type === 'number' || item.type === 'boolean')
      ) {
        // Create a new capabilities item with default values
        const capabilitiesItem = { ...item };
        capabilitiesItem.default =
          defaultDeviceCapabilities[0] === item.id && alarmCapabilities.length <= 0;
        selectableCapabilities.push(capabilitiesItem);
      }
      // Check for generic capabilities
      else if (item.type === 'string') {
        // Create a new capabilities item with default values
        const capabilitiesItem = { ...item };
        capabilitiesItem.default = false;
        selectableCapabilities.push(capabilitiesItem);
      }
    }

    selectableCapabilities.push({
      id: '.none',
      title: i18n.messageFormatter('common.none'),
      default: defaultDeviceCapabilities.length <= 0 && alarmCapabilities.length <= 0,
    });

    return selectableCapabilities;
  }, [i18n, device?.capabilitiesObj]);

  // Return a flat array of status indicator options
  const statusIndicatorOptions = useMemo(() => {
    const statusIndicatorOptions = [];

    // Check if the device allows custom indicators
    const allowDeviceCustomIndicator = () => {
      const deviceClass = device?.class;
      const deviceVirtualClass = device?.virtualClass;
      const excludedDeviceTypes = ['light', 'speaker', 'thermostat', 'lock'];
      if (deviceVirtualClass) {
        return !excludedDeviceTypes.includes(deviceVirtualClass);
      }
      return !excludedDeviceTypes.includes(deviceClass);
    };

    // If the device does not have selectable capabilities or the device does not allow custom indicators,
    // return an empty array
    if (allowDeviceCustomIndicator() === false || selectableCapabilities.length <= 1) {
      return [];
    }

    for (const capability of selectableCapabilities) {
      switch (true) {
        case capability.id === '.none':
          statusIndicatorOptions.push({
            key: capability.id,
            textValue: capability.title,
            indicator: null,
          });
          break;
        case capability.id?.startsWith('alarm_battery') ||
          capability.id?.startsWith('measure_battery'):
          statusIndicatorOptions.push({
            key: capability.id,
            textValue: capability.title,
            indicator: (
              <IndicatorBattery
                device={device}
                capabilities={capabilities}
                capabilityId={capability.id}
              />
            ),
          });
          break;
        case capability.id?.startsWith('meter_') || capability.id?.startsWith('measure_'):
          statusIndicatorOptions.push({
            key: capability.id,
            textValue: capability.title,
            indicator: (
              <IndicatorNumberStatic
                device={device}
                capabilities={capabilities}
                capabilityId={capability.id}
              />
            ),
          });
          break;
        case capability.id?.startsWith('alarm_'):
          statusIndicatorOptions.push({
            key: capability.id,
            textValue: capability.title,
            indicator: <IndicatorAlarmSpecificStatic />,
          });
          break;
        case capability.id === '.anyAlarm':
          statusIndicatorOptions.push({
            key: capability.id,
            textValue: capability.title,
            indicator: <IndicatorAlarmStatic />,
          });
          break;
        default:
          break;
      }
    }
    return statusIndicatorOptions;
  }, [device, capabilities, selectableCapabilities]);

  // Return the default value for the status indicator select
  const statusIndicatorDefaultValue = useMemo(() => {
    const capabilitiesObj = device?.capabilitiesObj;
    const uiIndicator = device?.uiIndicator;

    if (capabilitiesObj == null) return '';

    if (typeof uiIndicator === 'string') {
      return uiIndicator;
    }

    const defaultCapability = selectableCapabilities.filter((item) => {
      return item.default;
    })[0];

    if (defaultCapability) {
      return defaultCapability.id;
    }

    return 'none';
  }, [device?.capabilitiesObj, device?.uiIndicator, selectableCapabilities]);

  // Return a flat array of zone options with the corresponding level and icon
  const zonesOptions = useMemo(() => {
    return (
      zonesTree.tree?.list.map((zoneTreeData) => {
        const zone = zonesTree.byId[zoneTreeData.id];

        return {
          key: zoneTreeData.id,
          textValue: zone.name,
          level: zoneTreeData.level ?? 0,
          icon: HomeyImages.getIconForZone(zone.icon),
        };
      }) ?? []
    );
  }, [zonesTree.tree, zonesTree.byId]);

  const virtualClassOptions = useMemo(() => {
    const virtualClassOptions = [];
    const allowedVirtualClasses = deviceClasses[device?.class]?.allowedVirtual ?? [];

    if (api == null) {
      return [];
    }

    for (const virtualClass of allowedVirtualClasses) {
      const classInfo = deviceClasses[virtualClass];

      if (
        api.homey.platform === 'local' &&
        classInfo.minCompatibility != null &&
        semver.lt(semver.coerce(api.homey.softwareVersion), classInfo.minCompatibility)
      ) {
        continue;
      }

      virtualClassOptions.push({
        key: virtualClass,
        textValue: deviceClasses[virtualClass]?.title?.[locale] ?? virtualClass,
      });
    }

    if (virtualClassOptions.length > 0) {
      virtualClassOptions.push({
        key: 'default',
        textValue: i18n.messageFormatter('device.settings.noVirtualClass'),
      });
    }

    return virtualClassOptions;
  }, [device?.class, locale, i18n, api]);

  return (
    <S.Root>
      <S.NameTextField
        id="name"
        name="name"
        label="Name"
        type="text"
        orientation="vertical"
        defaultValue={device?.name}
        register={props.register}
        error={props.errors[props.id]}
      />
      <S.SelectWrapper ref={overlayTargetRef}>
        <DeviceSettingsGeneralIconSelect
          id="icon"
          name="icon"
          label="Icon"
          defaultValue={device?.iconOverride ?? 'default'}
          control={props.control}
          error={props.errors[props.id]}
          overlayTargetRef={overlayTargetRef}
          options={deviceIconOptions}
        />
        <DeviceSettingsGeneralStatusIndicatorSelect
          id="statusIndicator"
          name="statusIndicator"
          label="Status Indicator"
          defaultValue={statusIndicatorDefaultValue}
          control={props.control}
          error={props.errors[props.id]}
          overlayTargetRef={overlayTargetRef}
          options={statusIndicatorOptions}
        />
      </S.SelectWrapper>
      <S.ZoneSelect
        id="zone"
        name="zone"
        label="Zone"
        orientation="vertical"
        styleOption="optionZone"
        defaultValue={device?.zone}
        control={props.control}
        error={props.errors[props.id]}
        options={zonesOptions}
      />
      {virtualClassOptions.length > 0 && (
        <S.VirtualClassSelect
          id="virtualClass"
          name="virtualClass"
          label="Plugged In"
          orientation="vertical"
          styleOption="optionVirtualClass"
          defaultValue={device?.virtualClass ?? 'default'}
          control={props.control}
          error={props.errors[props.id]}
          options={virtualClassOptions}
        />
      )}
    </S.Root>
  );
}

function S() {}
DeviceSettingsGeneral.S = S;

S.Root = styled.div`
  margin-top: ${su(4)};
`;

S.NameTextField = styled(TextField)`
  margin-bottom: ${su(2)};
`;

S.SelectWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: ${su(2)};
  align-items: end;
  margin-bottom: ${su(2)};
`;

S.ZoneSelect = styled(SelectControllable)`
  margin-bottom: ${su(2)};
`;

S.VirtualClassSelect = styled(SelectControllable)`
  margin-bottom: ${su(2)};
`;
