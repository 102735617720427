import { useEffect, useRef, useState, useCallback } from 'react';
import styled from '@emotion/styled';
import CrossFrame from '../../lib/crossframe';
import { useLocale } from 'react-aria';

import { log } from '../../lib/__dev__';
import { promptMessage } from '../overlay/DialogPrompt';
import { ToastManager } from '../../ToastManager';
import { ResourceUtils } from '../../store/ResourceUtils';

import { useI18n } from '../../hooks/useI18nFormatters';
import { useMount } from '../../hooks/useMount';
import { useDriver } from '../../store/drivers/useDrivers';
import { useApi } from '../../store/HomeyStore';

import { theme } from '../../theme/theme';
import { su } from '../../theme/functions/su';

import { Throbber } from '../common/Throbber';

export function PairingFrame(props) {
  const { locale } = useLocale();
  const { i18n } = useI18n();

  const { driver, manager } = useDriver({ driverKey: props.driverKey });
  const [, setIsPairing] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const { api } = useApi();
  const [title, setTitle] = useState('');

  const iFrameRef = useRef();
  const instanceRef = useRef({
    crossFrame: null,
    session: null,
    socketMessageQueue: [],
    result: null,
  });

  instanceRef.current.onDone = props.onDone;
  instanceRef.current.onCancel = props.onCancel;

  if (props.instanceRef) {
    props.instanceRef.current = instanceRef.current;
  }

  const createSession = useCreateSession({
    instanceRef,
    iFrameRef,
    onBeforeCreate: () => {
      setIsPairing(true);
    },
    onError: () => {
      setIsPairing(false);
    },
  });

  const cancelSession = useCancelSession({
    instanceRef,
    iFrameRef,
    onSuccess: () => {
      setIsPairing(false);
      setIsReady(false);
    },
    onError: () => {
      setIsPairing(false);
      setIsReady(false);
    },
  });

  usePairMessageListener({ instanceRef, manager, isReady });

  useEffect(() => {
    const instance = instanceRef.current;

    if (manager != null && driver != null && props.type != null) {
      createSession({
        manager,
        driver,
        type: props.type,
        deviceId: props.deviceId,
        zoneId: props.zoneId,
      });
    }

    return function () {
      cancelSession({ manager });

      instance.result = null;
      instance.crossFrame?.destroy();
      instance.crossFrame = null;
    };
  }, [manager, driver, props.type, props.deviceId, props.zoneId, createSession, cancelSession]);

  useEffect(() => {
    const instance = instanceRef.current;
    const iFrame = iFrameRef.current;

    function handleReady(data, callback) {
      log('handleReady', data);
      setIsReady(true);
      instance.crossFrame.emit('_start', instance.session);
    }

    function handleAlert(data, callback) {
      log('handleAlert', data);
      window.alert(data.text);
      callback();
    }

    function handleConfirm(data, callback) {
      log('handleConfirm', data);
      const result = window.confirm(data.text);
      callback(null, result);
    }

    function handlePopup(data, callback) {
      log('handlePopup', data);
      promptMessage({
        type: 'confirm',
        message: i18n.messageFormatter('pair.appOpen'),
        onActionPress() {
          popup(data.url, data.opts);
        },
      })
        .then(() => {
          // handle sync in onActionPress
        })
        .catch(async () => {
          await cancelSession({ manager });
          instance.onCancel?.();
        });
      callback(null);
    }

    function handleGetLanguage(data, callback) {
      log('handleGetLanguage', data);
      callback(null, locale);
    }

    function handleGetDevmode(data, callback) {
      log('handleGetDevmode', data);
      callback(null, false);
    }

    function handleEmit({ event, data }, callback) {
      log('handleEmit', event, data);
      const session = instance.session;

      if (session?.id != null) {
        manager
          .emitPairingEvent({
            id: session.id,
            event: event,
            data: data,
          })
          .then((result) => {
            // todo check if still pairing
            log('emitPairingEvent:then', result);
            callback(null, result);
          })
          .catch((error) => {
            // todo check if still pairing
            console.error(error);
            callback(error);
          });
      }
    }

    function handleRegisterSocketEventListener(event) {
      log('handleRegisterSocketEventListener', event);
      const processedQueue = [];

      instance.socketMessageQueue.forEach((message, index) => {
        if (message.event === event) {
          if (instance.session?.id === message.sessionId) {
            proxyMessage(instance, manager, message);
          }

          processedQueue.push(index);
        }
      });

      log('processedQueueLength', processedQueue.length);
      instance.socketMessageQueue = instance.socketMessageQueue.filter((value, index) => {
        return processedQueue.includes(index) === false;
      });
    }

    async function handleDone() {
      log('handleDone');
      const result = instance.result;
      await cancelSession({ manager });
      instance.onDone?.(result ?? {});
    }

    function handleCreateDevice(data, callback) {
      log('handleCreateDevice', data);
      const session = instance.session;

      if (session?.id != null) {
        const device = {
          data: data.data,
          name: data.name,
          store: data.store,
          capabilities: data.capabilities,
          capabilitiesOptions: data.capabilitiesOptions,
          class: data.class,
          icon: data.icon,
          iconOverride: data.iconOverride,
          settings: data.settings,
          mobile: data.mobile,
          energy: data.energy,
        };

        manager
          .createPairSessionDevice({
            id: session.id,
            device: device,
          })
          .then((result) => {
            // todo check if still valid
            log('createPairSessionDevice', result);
            instance.result = {
              device: result,
            };
            callback();
          })
          .catch((error) => {
            // todo check if still valid
            console.error(error);
            ToastManager.handleError(error);
            callback();
          });
      }
    }

    function handleUpdateDevice(data, callback) {
      log('handleUpdateDevice', data);
      const session = instance.session;

      if (session?.id != null) {
        manager
          .updatePairSessionDevice({
            id: session.id,
            device: data,
          })
          .then((result) => {
            // todo check if still valid
            log('updatePairSessionDevice', result);
            callback();
          })
          .catch((error) => {
            // todo check if still valid
            console.error(error);
            callback();
          });
      }
    }

    function handleDeleteDevice(data, callback) {
      log('handleDeleteDevice', data);
      const session = instance.session;

      if (session?.id != null) {
        manager
          .deletePairSessionDevice({
            id: session.id,
          })
          .then((result) => {
            // todo check if still valid
            log('deletePairSessionDevice', result);
            callback();
          })
          .catch((error) => {
            // todo check if still valid
            console.error(error);
            callback();
          });
      }
    }

    function handleGetZwave(data, callback) {
      log('handleGetZwave', data);
      if (instance.session?.id != null) {
        callback(null, instance.session.zwave ?? {});
      }
    }

    function handleGetZigbee(data, callback) {
      log('handleGetZigbee', data);
      if (instance.session?.id != null) {
        callback(null, instance.session.zigbee ?? {});
      }
    }

    function handleGetZone(data, callback) {
      log('handleGetZone', data);
      callback(null, undefined);
    }

    function handleSetTitle(data) {
      setTitle(data);
    }

    function handleIsMatterAvailable(data, callback) {
      log('handleIsMatterAvailable', data);
      callback(null, {
        available: true,
        platform: 'web',
      });
    }

    function handlePerformNativeMatterCommissioning(data, callback) {
      log('handlePerformNativeMatterCommissioning', data);
      callback(new Error('not_available'), null);
    }

    // On iFrame load, destroy previous crossFrame and attach handlers.
    function handleLoadListener() {
      log('handleLoadListener');
      instance.crossFrame?.destroy();
      instance.crossFrame = null;

      instance.crossFrame = new CrossFrame(iFrame);
      instance.crossFrame
        .on('alert', handleAlert)
        .on('confirm', handleConfirm)
        .on('popup', handlePopup)
        .on('getLanguage', handleGetLanguage)
        .on('getDevmode', handleGetDevmode)
        .on('ready', handleReady)
        .on('done', handleDone)
        .on('emit', handleEmit)
        .on('registerSocketEventListener', handleRegisterSocketEventListener)
        .on('createDevice', handleCreateDevice)
        .on('updateDevice', handleUpdateDevice)
        .on('deleteDevice', handleDeleteDevice)
        .on('setTitle', handleSetTitle)
        .on('getZwave', handleGetZwave)
        .on('getZigBee', handleGetZigbee)
        .on('getZone', handleGetZone)
        .on('isMatterAvailable', handleIsMatterAvailable)
        .on('performNativeMatterCommissioning', handlePerformNativeMatterCommissioning);
    }

    iFrame.addEventListener('load', handleLoadListener);

    return function () {
      iFrame.removeEventListener('load', handleLoadListener);
    };
  }, [locale, i18n, manager, cancelSession]);

  function getTitle() {
    if (title?.length > 0) {
      return title;
    }

    switch (props.type) {
      case 'pair':
        // Add "device" for basic drivers (e.g. "Add Zigbee device")
        if (
          driver != null &&
          ResourceUtils.getOwnerUri(driver) === 'homey:manager:vdevice' &&
          (driver.id.includes('zigbee') ||
            driver.id.includes('zwave') ||
            driver.id.includes('infrared'))
        ) {
          return i18n.messageFormatter('pair.pairBasicDevice', { driverName: driver?.name ?? '' });
        } else {
          return i18n.messageFormatter('pair.pairDevice', { driverName: driver?.name ?? '' });
        }
      case 'unpair':
        return i18n.messageFormatter('pair.unpairDevice');
      case 'repair':
        return i18n.messageFormatter('pair.repairDevice');
      default:
        return '';
    }
  }

  return (
    <PairingFrame.Root className={props.className}>
      {isReady === false && (
        <PairingFrame.AbsoluteSpinner>
          <Throbber size={theme.icon.size_large} />
        </PairingFrame.AbsoluteSpinner>
      )}
      {api?.features.useSeamlessDevicePairingScreens ? (
        <PairingFrame.IFrame
          ref={iFrameRef}
          title="pair"
          sandbox="allow-scripts allow-forms"
          src="about:blank"
        />
      ) : (
        <>
          <PairingFrame.Header>
            <PairingFrame.Title>{getTitle()}</PairingFrame.Title>
          </PairingFrame.Header>
          <PairingFrame.IFrameWrapper>
            <PairingFrame.IFrame
              ref={iFrameRef}
              title="pair"
              sandbox="allow-scripts allow-forms"
              src="about:blank"
            />
          </PairingFrame.IFrameWrapper>
        </>
      )}
    </PairingFrame.Root>
  );
}

PairingFrame.Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
`;

PairingFrame.Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
`;

PairingFrame.Title = styled.div`
  color: ${theme.color.text_light};
`;

PairingFrame.IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
`;

PairingFrame.AbsoluteSpinner = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

PairingFrame.IFrame = styled.iframe`
  border: 0;
  flex: 1 1 auto;
`;

/**
 * IFrameWrapper is necessary to support DarkMode properly.
 */
PairingFrame.IFrameWrapper = styled.div`
  display: flex;
  flex: 1 1 0;
  padding: ${su(2)};
  border-radius: ${theme.borderRadius.default};
`;

function usePairMessageListener({ instanceRef, manager, isReady }) {
  useEffect(() => {
    const instance = instanceRef.current;

    function handlePairMessage(message) {
      log('handlePairMessage', message);

      if (message == null || instance.session?.id !== message.sessionId) {
        return;
      }

      if (isReady === false) {
        instance.socketMessageQueue.push(message);
        return;
      }

      proxyMessage(instance, manager, message);
    }

    manager?.addListener('pair.message', handlePairMessage);

    return function () {
      manager?.removeListener('pair.message', handlePairMessage);
    };
  }, [instanceRef, manager, isReady]);
}

function useCreateSession({ instanceRef, iFrameRef, onBeforeCreate, onError }) {
  const ref = useRef({});

  ref.current.onBeforeCreate = onBeforeCreate;
  ref.current.onError = onError;

  return useCallback(
    async ({ manager, driver, deviceId, type, zoneId }) => {
      log('call:createSession');
      const instance = instanceRef.current;
      const iFrame = iFrameRef.current;

      ref.current.onBeforeCreate?.();

      let session = null;

      try {
        session = await manager.createPairSession({
          pairsession: {
            type: type,
            deviceId: deviceId,
            driverUri: ResourceUtils.getOwnerUri(driver),
            driverId: driver.id,
            zoneId: zoneId,
          },
        });
      } catch (error) {
        ref.current.onError?.(error);
        ToastManager.handleError(error);
        return;
      }

      const src = `${manager._baseUrl}${session.url}`;

      log('session', session);
      log(src);

      function emitPairingHeartbeat() {
        manager.emitPairingHeartbeat({ id: session.id }).catch((error) => {
          // todo
          // handleDone
          // reset
          ToastManager.handleError(error);
        });
      }

      iFrame.src = src;
      instance.session = session;
      instance.result = null;
      instance.heartbeatInterval = setInterval(emitPairingHeartbeat, 10000);
    },
    [instanceRef, iFrameRef]
  );
}

function useCancelSession({ instanceRef, iFrameRef, onSuccess, onError }) {
  const ref = useRef({});
  const mount = useMount();

  ref.current.onSuccess = onSuccess;
  ref.current.onError = onError;

  return useCallback(
    async ({ manager }) => {
      log('call:cancelSession');
      const instance = instanceRef.current;
      const iFrame = iFrameRef.current;
      const session = instance.session;

      clearInterval(instance.heartbeatInterval);

      if (session?.id != null) {
        try {
          await manager.deletePairSession({ id: session.id });

          instance.session = null;
          instance.result = null;

          if (mount.isMounted) {
            iFrame.src = 'about:blank';
            ref.current.onSuccess?.();
          }
        } catch (error) {
          instance.session = null;
          instance.result = null;

          if (mount.isMounted) {
            iFrame.src = 'about:blank';
            ref.current.onError?.(error);
          }

          ToastManager.handleError(error);
        }
      }
    },
    [instanceRef, iFrameRef, mount]
  );
}

function popup(url, opts) {
  opts = opts || {};
  opts.width = opts.width || 500;
  opts.height = opts.height || 800;

  const left = window.screen.width / 2 - opts.width / 2;
  const top = window.screen.height / 2 - opts.height / 2;

  window.open(
    url,
    'homey_dialog',
    'width=' +
      opts.width +
      ', height=' +
      opts.height +
      ', left=' +
      left +
      ', top=' +
      top +
      ', menubar=no, status=no, toolbar=no'
  );
}

function proxyMessage(instance, manager, message) {
  if (instance.session?.id != null) {
    instance.crossFrame.emit('pair.message', message, (...args) => {
      if (instance.session?.id != null) {
        manager
          .emitPairingCallback({
            id: instance.session.id,
            callbackId: message.callbackId,
            data: args,
          })
          .then((result) => {
            console.log(result);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  }
}
