import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import debounce from 'lodash.debounce';
import { v4 as uuid } from 'uuid';

import { useCollator, useLocale } from 'react-aria';
import { useI18n } from '../../../../hooks/useI18nFormatters';

import { appsAPI } from '../../../../lib/appsAPI';
import { ToastManager } from '../../../../ToastManager';

import { useDriversByUri } from '../../../../store/drivers/useDrivers';
import { useApi } from '../../../../store/HomeyStore';
import { useAppsById } from '../../../../store/apps/useApps';

import { theme } from '../../../../theme/theme';
import { su } from '../../../../theme/functions/su';
import { animationFade } from '../../../../theme/animations/animationFade';

import { ExternalLink } from '../../../common/link/ExernalLink';
import { Icon } from '../../../common/Icon';
import { ListBox } from '../../../list-box/ListBox';
import { SearchField } from '../../../common/search-field/SearchField';

import { AppItem } from './AppItem';
import { GridSection } from './GridSection';

import { iconPersonGroupFill } from '../../../../theme/icons/interface/person-group-fill/person-group-fill';
import { iconCheckmarkSealFill } from '../../../../theme/icons/interface/checkmark-seal-fill/checkmark-seal-fill';
import { iconSquareArrowUpRight } from '../../../../theme/icons/interface/square-arrow-up-right/square-arrow-up-right';
import { iconSearch } from '../../../../theme/icons/interface/magnifying-glass/magnifying-glass';

export function SearchView(props) {
  const { locale } = useLocale();
  const { i18n } = useI18n();
  const collator = useCollator();

  const instanceRef = useRef({});
  const searchFieldRef = useRef();

  const { api } = useApi();
  const apps = useAppsById();
  const drivers = useDriversByUri();

  const [state, setState] = useState({
    officialApps: null,
    communityApps: null,
    localApps: null,
    isSearch: false,
  });

  useEffect(() => {
    if (drivers.byUri != null && searchFieldRef.current?.value.length === 0) {
      setState({
        officialApps: null,
        communityApps: null,
        localApps: {
          ...drivers.byUri,
        },
        isSearch: false,
      });
    }
  }, [drivers.byUri]);

  const handleChange = useMemo(() => {
    return debounce(async (value) => {
      const id = (instanceRef.current.pendingId = uuid());

      if (value.length === 0) {
        setState({
          officialApps: null,
          communityApps: null,
          localApps: {
            ...drivers.byUri,
          },
          isSearch: false,
        });
        return;
      }

      try {
        const storeApps = await appsAPI.searchApps({
          query: value,
          homeyVersion: api.homeyVersion,
          homeyPlatform: api.homey.platform,
          homeyPlatformVersion: String(api.homey.platformVersion),
          homeyModel: api.homey.model,
          language: locale,
        });

        if (id !== instanceRef.current.pendingId) return;

        const officialApps = {};
        const communityApps = {};

        storeApps.forEach((app) => {
          if (!(app.driversCount > 0)) return;

          const appUri = `homey:app:${app.id}`;
          if (app.authorVerified) {
            officialApps[appUri] = drivers.byUri[appUri] ?? { appObj: app };
          } else {
            communityApps[appUri] = drivers.byUri[appUri] ?? { appObj: app };
          }
        });

        setState({
          officialApps: officialApps,
          communityApps: communityApps,
          localApps: {
            ...drivers.byUri,
          },
          isSearch: true,
        });
      } catch (error) {
        ToastManager.handleError(error);
      }
    }, 300);
  }, [api, locale, drivers.byUri]);

  const items = useMemo(() => {
    function formatApps(appsObject) {
      return Object.entries(appsObject ?? {}).map(([uri, drivers]) => {
        const owner = {
          name: drivers.ownerName,
          color: drivers.ownerColor,
          iconUrl: null,
        };
        const app = apps.byId?.[drivers.ownerId];

        if (app != null) {
          const ownerIconId = app.iconObj?.id;
          owner.iconUrl = `https://icons-cdn.athom.com/${ownerIconId}.svg`;
        }

        if (drivers.appObj) {
          owner.name = drivers.appObj.name;
          owner.color = drivers.appObj.brandColor;
          owner.iconUrl = drivers.appObj.icon;
        }

        return {
          key: uri,
          type: 'app',
          textValue: owner.name,
          context: {
            owner: owner,
          },
        };
      });
    }

    const officialApps = formatApps(state.officialApps);
    const communityApps = formatApps(state.communityApps);
    const localApps = formatApps(state.localApps);
    localApps.sort((firstEl, secondEl) => {
      if (firstEl.key === 'homey:manager:vdevice') {
        return -1;
      }

      if (secondEl.key === 'homey:manager:vdevice') {
        return 1;
      }

      return collator.compare(firstEl.textValue, secondEl.textValue);
    });

    return {
      official: [
        {
          key: 'official',
          children: officialApps,
        },
      ],
      community: [
        {
          key: 'community',
          children: communityApps,
        },
      ],
      local: [
        {
          key: 'local',
          children: localApps,
        },
      ],
    };
  }, [collator, state.localApps, state.officialApps, state.communityApps, apps.byId]);

  function onSelectionChange(keys, listState) {
    const item = listState.collection.getItem(keys[0]);
    props.onDriverUriSelect({
      uri: item?.key ?? null,
    });
  }

  function renderLabel({ label, props }) {
    return (
      <SearchView.Label {...props}>
        {label.iconUrl && (
          <Icon url={label.iconUrl} size={theme.icon.size_default} color={theme.icon.color_dark} />
        )}
        {label.text}
      </SearchView.Label>
    );
  }

  function renderSection() {
    return <GridSection />;
  }

  function renderItem() {
    return <AppItem />;
  }

  const isReady = drivers.loading === false && drivers.error == null;

  useEffect(() => {
    requestAnimationFrame(() => {
      searchFieldRef.current.focus();
    });
  }, []);

  return (
    <SearchView.Root>
      <SearchView.SearchField
        ref={searchFieldRef}
        aria-label="Search"
        borderRadius={theme.borderRadius.default}
        placeholder={i18n.messageFormatter('pair.searchPlaceholder')}
        onChange={handleChange}
        onKeyDown={(event) => {
          // If it was empty and escape it pressed propagate event so that
          // overlays can be closed with keyboard.
          if (event.key === 'Escape' && event.currentTarget.value === '') {
            event.continuePropagation();
          }
        }}
      />

      {isReady && state.isSearch && (
        <SearchView.SearchResults>
          {items.official[0].children.length !== 0 && (
            <ListBox
              label={{
                iconUrl: api.homey.platform === 'local' ? iconCheckmarkSealFill : null,
                text:
                  api.homey.platform === 'local'
                    ? i18n.messageFormatter('pair.officialTitle')
                    : i18n.messageFormatter('pair.searchResults'),
              }}
              items={items.official}
              selectedKeys={props.selectedDriverUri?.uri ? [props.selectedDriverUri.uri] : []}
              onSelectionChange={onSelectionChange}
              renderLabel={renderLabel}
              renderSection={renderSection}
              renderItem={renderItem}
            />
          )}

          {items.community[0].children.length !== 0 && (
            <ListBox
              label={{
                iconUrl: iconPersonGroupFill,
                text: i18n.messageFormatter('pair.communityTitle'),
              }}
              items={items.community}
              selectedKeys={props.selectedDriverUri?.uri ? [props.selectedDriverUri.uri] : []}
              onSelectionChange={onSelectionChange}
              renderLabel={renderLabel}
              renderSection={renderSection}
              renderItem={renderItem}
            />
          )}

          {items.official[0].children.length === 0 && items.community[0].children.length !== 0 && (
            <>
              <SearchView.Label>
                <Icon
                  url={iconCheckmarkSealFill}
                  size={theme.icon.size_default}
                  color={theme.icon.color_dark}
                />
                {i18n.messageFormatter('pair.officialTitle')}
              </SearchView.Label>
              <SearchView.NoResultsText>
                {i18n.messageFormatter('pair.noOfficialAppsFound', {
                  requestABrand: (
                    <SearchView.Link
                      url={'https://request-a-brand.homey.app/boards/request-a-brand'}
                    >
                      {i18n.messageFormatter('pair.requestABrand')}
                      <Icon
                        url={iconSquareArrowUpRight}
                        size={theme.icon.size_small}
                        color={theme.icon.color_blue}
                      />
                    </SearchView.Link>
                  ),
                })}
              </SearchView.NoResultsText>
            </>
          )}

          {items.official[0].children.length === 0 && items.community[0].children.length === 0 && (
            <SearchView.NoResults>
              <Icon url={iconSearch} size={'48px'} color={theme.icon.color_light} />
              <SearchView.NoResultsTitle>
                {i18n.messageFormatter('pair.noSearchResults')}
              </SearchView.NoResultsTitle>
              <SearchView.NoResultsText>
                {i18n.messageFormatter('pair.noAppsFound', {
                  requestABrand: (
                    <SearchView.Link
                      key={'request-a-brand'}
                      url={'https://request-a-brand.homey.app/boards/request-a-brand'}
                    >
                      {i18n.messageFormatter('pair.requestABrand')}
                      <Icon
                        url={iconSquareArrowUpRight}
                        size={theme.icon.size_small}
                        color={theme.icon.color_blue}
                      />
                    </SearchView.Link>
                  ),
                })}
              </SearchView.NoResultsText>
            </SearchView.NoResults>
          )}
        </SearchView.SearchResults>
      )}

      {isReady && !state.isSearch && (
        <ListBox
          label={{ text: i18n.messageFormatter('pair.brandsTitle') }}
          items={items.local}
          selectedKeys={props.selectedDriverUri?.uri ? [props.selectedDriverUri.uri] : []}
          onSelectionChange={onSelectionChange}
          renderLabel={renderLabel}
          renderSection={renderSection}
          renderItem={renderItem}
        />
      )}
    </SearchView.Root>
  );
}

SearchView.Root = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  animation: ${animationFade.in} ${theme.duration.slow} ease-out 1 forwards;
`;

SearchView.SearchField = styled(SearchField)`
  flex: 0 0 auto;

  ${SearchField.Input} {
    height: ${theme.input.height_large};
    line-height: ${theme.input.height_large};
  }
`;

SearchView.SearchResults = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

SearchView.Label = styled.h3`
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  align-items: center;
  justify-content: start;
  width: 100%;
  margin: ${su(4, 0, 2)};
  line-height: 30px;

  &:not(:first-of-type) {
    margin-top: ${su(1)};
    padding-top: ${su(2)};
    border-top: 1px solid ${theme.color.line};
  }
`;

SearchView.EmptyMessage = styled.div`
  color: ${theme.color.text_light};
`;

SearchView.Link = styled(ExternalLink)`
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: 5px;
  align-items: center;
  color: ${theme.color.text_link};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

SearchView.NoResults = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: pre-wrap;
  padding-bottom: ${su(5)};
`;

SearchView.NoResultsTitle = styled.h2`
  margin: 24px 0 8px;
  color: ${theme.color.text};
`;

SearchView.NoResultsText = styled.p`
  margin: 0;
  color: ${theme.color.text_light};
  line-height: 1.5;
`;
