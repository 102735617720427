import { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { useI18n } from '../../hooks/useI18nFormatters';

import { ToastManager } from '../../ToastManager';

import { CapUtils } from '../../lib/capabilities/capabilities';

import { theme } from '../../theme/theme';
import { scrollbarDarkTiny } from '../../theme/elements/scrollbars';
import { animationFade } from '../../theme/animations/animationFade';
import { Pulse } from '../common/Pulse';
import { SwitchButton } from '../common/SwitchButton';

const blackList = ['speaker_next', 'speaker_prev'];

export function DeviceCapabilityList(props) {
  const { i18n } = useI18n();

  const uiCapabilities = useMemo(() => {
    let mappedWithComponentId = [];

    if (props.device?.ui?.components) {
      props.device.ui.components.forEach((component) => {
        if (component.id === 'button' || component.id === 'color') return;

        mappedWithComponentId = [
          ...mappedWithComponentId,
          ...component.capabilities.reduce((accumulator, capabilityId) => {
            const baseId = capabilityId.split('.')[0];
            if (blackList.includes(baseId)) return accumulator;

            accumulator.push({
              componentId: component.id,
              capabilityId: capabilityId,
            });

            return accumulator;
          }, []),
        ];
      });
    }

    // if the uiIndicator is set put the coresponding capability at the front
    if (props.device?.uiIndicator != null) {
      const index = mappedWithComponentId.findIndex(
        (item) => item.capabilityId === props.device?.uiIndicator
      );

      if (index !== -1) {
        const [item] = mappedWithComponentId.splice(index, 1);

        return [item, ...mappedWithComponentId];
      }
    }

    return mappedWithComponentId;
  }, [props.device?.ui?.components, props.device?.uiIndicator]);

  return (
    <DeviceCapabilityList.Root isDragPreview={props.isDragPreview}>
      {uiCapabilities.map(({ componentId, capabilityId }) => {
        return (
          <DeviceCapabilityListItem
            key={capabilityId}
            device={props.device}
            componentId={componentId}
            capabilityId={capabilityId}
            capability={props.capabilities?.[capabilityId] ?? null}
            quickActionValue={props.quickActionValue}
            quickActionId={props.quickActionId}
            onQuickAction={props.onQuickAction}
            messageFormatter={i18n.messageFormatter}
          />
        );
      })}
    </DeviceCapabilityList.Root>
  );
}

// prettier-ignore
DeviceCapabilityList.Root = styled.div`
  ${scrollbarDarkTiny};
  overflow-y: hidden;
  overflow-x: hidden;
  margin-top: 10px;
  margin-right: -8px;
  padding-right: 8px;
  margin-bottom: 0;
  transition: margin-bottom ${theme.duration.fast} ease-in-out;
  scroll-snap-type: y mandatory;

  ${(props) =>
    props.isDragPreview !== true &&
    css`
      // opacity: 0;
      // animation: ${animationFade.in} ${theme.duration.slow} ${theme.duration.fast} ease-out 1 forwards;
    `};


  &:hover {
    overflow-y: scroll;
  }
`;

function DeviceCapabilityListItem(props) {
  let [value, setValue] = useState(props.capability?.value ?? null);

  if (props.capability?.id === props.quickActionId) {
    value = props.quickActionValue;
  }

  useEffect(() => {
    if (props.capability && props.capability.id !== props.quickActionId) {
      const unregister = props.capability.onChange(({ value }) => {
        setValue(value);
      });

      setValue(props.capability.value);
      return unregister;
    }
  }, [props.capability, props.quickActionId]);

  if (props.capability == null) return null;

  let component = null;

  if (props.capability.id.startsWith('alarm_')) {
    component = <Pulse active={value} color={theme.color.red} />;
  }

  if (props.componentId === 'toggle') {
    if (props.capability.id === props.quickActionId) {
      component = <SwitchButton onPress={props.onQuickAction} isSelected={value} small={true} />;
    } else {
      component = (
        <SwitchButton
          onPress={() => {
            if (props.capability.getable === true) {
              const prevValue = value;
              const nextValue = !value;
              setValue(nextValue);
              props.capability
                .setValue(nextValue)
                .then(({ transactionId, transactionTime, value }) => {})
                .catch((error) => {
                  setValue(prevValue);
                  ToastManager.handleError(error);
                });
            } else {
              props.capability
                .setValue(true)
                .then(({ transactionId, transactionTime, value }) => {})
                .catch((error) => {
                  ToastManager.handleError(error);
                });
            }
          }}
          isSelected={value}
          small={true}
        />
      );
    }
  }

  return (
    <DeviceCapabilityListItem.Root>
      <DeviceCapabilityListItem.Label title={props.capability.title}>
        {props.capability.title}
      </DeviceCapabilityListItem.Label>
      <DeviceCapabilityListItem.Value>
        {component != null
          ? component
          : CapUtils.getFormattedValue({
              messageFormatter: props.messageFormatter,
              capability: props.capability,
              value: value,
            })}
      </DeviceCapabilityListItem.Value>
    </DeviceCapabilityListItem.Root>
  );
}

DeviceCapabilityListItem.Root = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 24px;
  scroll-snap-align: start;
  min-width: calc(
    var(--tile-size) - 20px
  ); // force min-width to prevent scroll issues over several browsers: chrome/safari/chrome

  // if more then 4 items, compensate last item with padding. With 3 or lower extra padding doesn't matter.
  // When exactly 4 items we cannot have extra padding because then a scrollbar appears
  &:last-of-type:not(:nth-of-type(4)) {
    scroll-snap-align: end;
    padding-bottom: 11px;
  }
`;

DeviceCapabilityListItem.Label = styled.div`
  font-size: ${theme.fontSize.small};
  color: ${theme.color.text_light};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 25%;
`;

DeviceCapabilityListItem.Value = styled.div`
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeight.medium};
  white-space: nowrap;
  flex: 1 1 auto;
  text-align: right;
  margin-left: 5px;
  min-width: min-content;
  max-width: 75%;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 8px;
  margin-right: -8px;
`;
