import { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { AnimatePresence } from 'framer-motion';
import { Prompt, useLocation } from 'react-router-dom';

import { RouteManager } from '../../../RouteManager';
import { ToastManager } from '../../../ToastManager';

import { FlowTest } from './useFlowTest';
import { FlowEditor, useFlowEditor, useSetFlowEditor } from './useFlowEditor';
import { ResourceUtils } from '../../../store/ResourceUtils';

import { TokenContext } from '../TokenContext';

import { useQuery } from '../../../hooks/useQuery';
import { useI18n } from '../../../hooks/useI18nFormatters';
import { useFlowCards } from '../../../store/flow-cards/useFlowCards';
import { useHasUnsavedChanges } from './useHasUnsavedChanges';

import { su } from '../../../theme/functions/su';
import { theme } from '../../../theme/theme';

import { GradientButton } from '../../../components/buttons/GradientButton';
import { MonoButton } from '../../../components/buttons/MonoButton';
import { SpinnerFade } from '../../../components/common/SpinnerFade';
import { Scroll } from '../../../components/common/Scroll';

import { FlowCardTrigger } from './FlowCardTrigger';
import { FlowCardConditions } from './FlowCardConditions';
import { FlowCardActions } from './FlowCardActions';
import { FlowCardExplorerDialog } from '../flow-card-explorer-dialog/FlowCardExplorerDialog';
import { FlowTestOverlayTrigger } from './FlowTestOverlayTrigger';
import { FlowName } from './FlowName';

import { iconCheckmark } from '../../../theme/icons/interface/checkmark';
import { iconCheckCircle } from '../../../theme/icons/interface/check-circle';
import { iconFlow } from '../../../theme/icons/system/flow/flow';
import { iconPlay } from '../../../theme/icons/interface/play';

export function FlowEditorView(props) {
  const { i18n } = useI18n();

  const location = useLocation();
  const params = useQuery();
  const resetId = params.get('id');
  const folderId = params.get('folderId');

  useSetFlowEditor({
    flowId: props.flowId,
    folderId: folderId,
    resetId: resetId,
  });

  const { flow, initialFlow, saving } = useFlowEditor();
  const flowCards = useFlowCards();
  const tokenContext = useTriggerTokensByKey({ flow, flowCards });

  const [flowCardPickerState, setFlowCardPickerState] = useState();

  const canTestWithoutTokens = Object.keys(tokenContext.tokensByKey).length === 0;

  const hasUnsavedChanges = useHasUnsavedChanges({ flow, initialFlow });

  function handleNavigateAway(nextLocation, action) {
    if (
      `${nextLocation.pathname}${nextLocation.search}` === `${location.pathname}${location.search}`
    ) {
      return true;
    }

    if (hasUnsavedChanges) {
      return i18n.messageFormatter('flow.unsavedChanges');
    }

    return true;
  }

  return (
    <TokenContext.Provider value={tokenContext}>
      {flow != null && flowCards.loading === false && (
        <Scroll>
          <FlowEditorView.Root>
            <FlowEditorView.Content>
              <FlowEditorView.Header>
                <FlowName
                  autoFocus={location.state?.focus === 'name'}
                  key={`${flow.id}-${resetId}`}
                  aria-label="Flow name"
                  defaultValue={flow.name}
                  placeholder={i18n.messageFormatter('flow.enterName')}
                  onRequestSave={(value) => {
                    FlowEditor.setProperty({ property: 'name', value: value });
                  }}
                  onRequestCancel={() => {
                    FlowEditor.setProperty({ property: 'name', value: flow.name });
                  }}
                />
              </FlowEditorView.Header>
              <FlowEditorView.Center>
                <FlowCardTrigger
                  trigger={flow.trigger}
                  flowCards={flowCards}
                  setFlowCardPickerState={setFlowCardPickerState}
                />
                <FlowCardConditions
                  conditions={flow.conditions}
                  flowCards={flowCards}
                  setFlowCardPickerState={setFlowCardPickerState}
                />
                <FlowCardActions
                  actions={flow.actions}
                  flowCards={flowCards}
                  setFlowCardPickerState={setFlowCardPickerState}
                />

                <AnimatePresence>
                  {flowCardPickerState != null && (
                    <FlowCardExplorerDialog
                      advanced={false}
                      cardType={flowCardPickerState.type}
                      currentCard={flowCardPickerState.currentCard}
                      onCloseRequest={() => {
                        setFlowCardPickerState(null);
                      }}
                      onCardSelect={(cardKey, card) => {
                        const index = flowCardPickerState.cardIndex;
                        const type = flowCardPickerState.type;

                        setFlowCardPickerState(null);
                        switch (type) {
                          case 'trigger':
                            FlowEditor.setTrigger({
                              cardKey,
                              cardIndex: index,
                              card,
                            });
                            break;
                          case 'condition':
                            FlowEditor.setCondition({
                              cardKey,
                              cardIndex: index,
                              card,
                            });
                            break;
                          case 'action':
                            FlowEditor.setAction({
                              cardKey,
                              cardIndex: index,
                              card,
                            });
                            break;
                          default:
                            break;
                        }
                      }}
                    />
                  )}
                </AnimatePresence>
              </FlowEditorView.Center>
              <FlowEditorView.StickyActions>
                <FlowEditorView.StickyActionsCenter>
                  <FlowTestOverlayTrigger
                    triggerTokensById={tokenContext.tokensByKey}
                    renderTrigger={(triggerRef, triggerProps, triggerState) => {
                      return (
                        <MonoButton
                          {...triggerProps}
                          ref={triggerRef}
                          styleIconColor="green"
                          onPress={() => {
                            const validation = FlowEditor.validate({ type: 'test' });
                            const isValid = Object.keys(validation ?? {}).length === 0;

                            if (isValid === false) {
                              const message = Object.entries(validation)
                                .map(([key, value]) => {
                                  if (typeof value === 'object' && value != null) {
                                    return i18n.messageFormatter(value.type, {
                                      argTitle: value.argTitle,
                                      cardTitle: value.cardTitle,
                                    });
                                  }
                                  return value;
                                })
                                .join('\n');
                              ToastManager.addError({ message: message });
                              return;
                            }

                            if (canTestWithoutTokens) {
                              FlowTest.testFlow().then(() => {
                                ToastManager.add({
                                  icon: iconFlow,
                                  message: i18n.messageFormatter('flow.startTest'),
                                });
                              });
                              return;
                            }

                            triggerState.open();
                          }}
                        >
                          <MonoButton.Icon size={theme.icon.size_default} url={iconPlay} />
                          <MonoButton.Text>{i18n.messageFormatter('flow.test')}</MonoButton.Text>
                        </MonoButton>
                      );
                    }}
                  />

                  <GradientButton
                    isDisabled={saving}
                    onPress={() => {
                      if (saving) return;

                      const validation = FlowEditor.validate();
                      const isValid = Object.keys(validation ?? {}).length === 0;

                      if (isValid === false) {
                        const message = Object.entries(validation)
                          .map(([key, value]) => {
                            if (typeof value === 'object' && value != null) {
                              return i18n.messageFormatter(value.type, {
                                argTitle: value.argTitle,
                                cardTitle: value.cardTitle,
                              });
                            }
                            return value;
                          })
                          .join('\n');
                        ToastManager.addError({ message: message });
                        return;
                      }

                      if (flow.id === 'create') {
                        FlowEditor.createFlow()
                          .then((result) => {
                            RouteManager.toFlow(result.id);
                            ToastManager.add({
                              icon: iconCheckCircle,
                              iconColor: theme.color.highlight,
                              message: i18n.messageFormatter('flow.saveSuccess'),
                            });
                          })
                          .catch(ToastManager.handleError);
                      } else {
                        FlowEditor.saveFlow()
                          .then(() => {
                            ToastManager.add({
                              icon: iconCheckCircle,
                              iconColor: theme.color.highlight,
                              message: i18n.messageFormatter('flow.saveSuccess'),
                            });
                          })
                          .catch(ToastManager.handleError);
                      }
                    }}
                  >
                    <SpinnerFade
                      url={iconCheckmark}
                      size={theme.icon.size_small}
                      color={theme.color.white}
                      isActive={saving === true}
                    >
                      <GradientButton.Icon
                        url={iconCheckmark}
                        size={theme.icon.size_small}
                        color={theme.color.white}
                      />
                    </SpinnerFade>
                    <GradientButton.Text>
                      {/* {saving === true
                        ? `${i18n.messageFormatter('common.saving')}...`
                        : i18n.messageFormatter('common.save')} */}
                      {i18n.messageFormatter('common.save')}
                    </GradientButton.Text>
                  </GradientButton>
                </FlowEditorView.StickyActionsCenter>
              </FlowEditorView.StickyActions>
            </FlowEditorView.Content>
          </FlowEditorView.Root>
        </Scroll>
      )}

      <Prompt message={handleNavigateAway} />
    </TokenContext.Provider>
  );
}

function useTriggerTokensByKey({ flow, flowCards }) {
  const triggerData = flow?.trigger;
  const key = ResourceUtils.getKey(triggerData);
  const triggerCard = flowCards.triggers.byKey?.[key];

  return useMemo(() => {
    const tokens = triggerCard?.tokens ?? [];

    const tokensByKey = {};

    for (const token of tokens) {
      tokensByKey[token.id] = token;
    }

    return {
      tokensByKey,
    };
  }, [triggerCard]);
}

FlowEditorView.Root = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: ${su(6, 3)};
`;

FlowEditorView.Content = styled.div`
  width: 100%;
`;

FlowEditorView.Header = styled.header`
  min-width: 340px;
  width: 100%;
  max-width: 600px;
  text-align: center;
  margin: 0 auto;
`;

FlowEditorView.Center = styled.div`
  width: 340px;
  margin: 0 auto;
`;

FlowEditorView.StickyActions = styled.div`
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  background: ${theme.color.body};
  padding: ${su(2)} 0;
  z-index: 10;
  width: 100%;
  transition: ${theme.transition.micro};

  &::before {
    content: '';
    position: absolute;
    background: radial-gradient(ellipse at 50% 100%, ${theme.color.mono_o_10}, transparent 66%);
    height: 20px;
    bottom: 100%;
    width: 100%;
    left: 0;
    opacity: 1;
    transition: opacity 200ms ease-in-out;

    .is-scroll-bottom & {
      opacity: 0;
    }
  }
`;

FlowEditorView.StickyActionsCenter = styled.div`
  display: flex;
  justify-content: space-evenly;
  max-width: 340px;
  margin: 0 auto;

  > * {
    width: 100%;
  }

  & > :first-of-type {
    margin-right: 20px;
  }
`;
